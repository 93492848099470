/* all mobile view */

.search-term {
  color: #ffffff;
  text-align: center;
  margin-top: 30px;
  font-size: 1.3rem;
}
.search-results-wrapper-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
}

/*  when searching in files  */
.files-search-result-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #ffffff;
  width: 80%;
  max-width: 420px;
  border-radius: 12px;
  margin-bottom: 20px;
  padding: 20px;
}
.files-search-result-mobile .title {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  font-weight: 500;
  width: 100%;
  margin-bottom: 10px;
}
.files-search-result-mobile .title span {
  display: block;
}

.files-search-result-mobile .message {
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  height: 100px;
}
.files-search-result-mobile .download-btn {
  margin-inline-start: 5%;
  margin-block-start: 15px;
}

.files-search-result-mobile .download-btn button {
  background-color: #620402;
  border-radius: 55px;
  color: #ffffff;
  padding: 10px 20px;
}
/*  when searching in lessons  */
.lessons-search-results-mobile {
  background-color: #000000;
  margin: 0 20px;
  max-width: 450px;
}
.lessons-search-results-mobile .main {
  margin-top: 30px;
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  width: 100%;
  padding: 0 50px;
  color: #ffffff;
}

.lessons-search-results-mobile .main .left .title {
  text-align: left;
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.lessons-search-results-mobile .main .left .description {
  text-align: left;
  font-size: 1rem;
}

.lessons-search-results-mobile .main .right {
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 3px;
}
.lessons-search-results-mobile .line {
  border-bottom: 2px solid #ffffff;
  margin: 20px auto;
  width: 78%;
  text-align: center;
}

.lessons-search-results-mobile .bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  margin-bottom: 20px;
  color: #ffffff;
}
.lessons-search-results-mobile .bottom .download-attached-files {
  font-size: 1rem;
}

/*  when searching in messages  */

.message-search-results-mobile {
  background-color: #efefef;
  border-radius: 12px;
  margin-bottom: 10px;
  max-width: 500px;
  width: 90%;
  padding: 20px;
}
.message-search-results-mobile .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.message-search-results-mobile .title {
  font-size: 1.2rem;
  font-weight: 800;
  color: #000000;
  width: 70%;
}

.message-search-results-mobile .message {
  font-size: 1rem;
  color: #000000;
  margin: 10px 0;
  width: 70%;
}

.message-search-results-mobile .bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.message-search-results-mobile .bottom .left {
}
.message-search-results-mobile .bottom .left button {
  background-color: #620402;
  color: #ffffff;
  border-radius: 55px;

  padding: 10px 20px;
}

.message-search-results-mobile .bottom .right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

@media (max-width: 500px) {
  .lessons-search-results-mobile .main {
    padding: 0 25px;
    gap: 30px;
  }

  .lessons-search-results-mobile .main .left .title {
    font-size: 1rem;
  }

  .lessons-search-results-mobile .main .left .description {
    font-size: 0.8rem;
  }
  .lessons-search-results-mobile .bottom {
    justify-content: space-around;
    padding: 0 20px;
  }
  .lessons-search-results-mobile .bottom .download-attached-files {
    font-size: 0.9rem;
  }
  .lessons-search-results-mobile .bottom .video-length {
    font-size: 0.8rem;
  }
}

@media (max-width: 350px) {
  .lessons-search-results-mobile .bottom .download-attached-files {
    font-size: 0.6rem;
  }
  .message-search-results-mobile .top .date-and-name,
  .message-search-results-mobile .top .location {
    font-size: 0.7rem;
  }

  .message-search-results-mobile .bottom .left button {
    padding: 5px 12px;
    font-size: 0.8rem;
  }

  .message-search-results-mobile .bottom .right {
    font-size: 0.8rem;
    width: 30%;
    gap: 0px;
  }
}
