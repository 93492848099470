/* desktop */
.invite-distributor-form {
  width: 30vw;
  margin-bottom: 0;
  background-color: #000000;
  padding: 20px 0 40px 0;
  border-radius: 0 0 12px 12px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.invite-distributor-form .form-wrapper {
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.invite-distributor-form .form-wrapper select {
  /* width: 99%; */
}
.invite-distributor-form .form-wrapper input,
.invite-distributor-form .form-wrapper select {
  background-color: #535353;
  padding-inline-start: 20px;
  border-radius: 12px 12px 0 0;
  height: 6vh;
  width: 80%;
  color: #ffffff;
}
/* .invite-distributor-form .form-wrapper select {
  appearance: none;
} */
.invite-distributor-form .form-wrapper input::placeholder {
  color: #ffffff;
  font-size: 1rem;
}

.invite-distributor-form .form-wrapper-btn input {
  background-color: #cb2a27;
  padding: 10px 30px;
  color: #ffffff;
  border-radius: 55px;
}

/* mobile   */
.invit-dist-mobile .invite-distributor-form {
  width: 100vw;
  background-color: transparent;
}

.invit-dist-mobile .invite-distributor-form .form-wrapper {
  width: 90%;
}

.invit-dist-mobile .invite-distributor-form .form-wrapper input,
.invit-dist-mobile .invite-distributor-form .form-wrapper select {
  height: 8vh;
}
