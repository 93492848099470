.users-distributor-popup {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  background-color: #ffffff;
  padding: 20px;
  width: 80vw;
  max-width: 500px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  z-index: 6000;
}
.users-distributor-popup .close {
  margin-left: auto;
}
.users-distributor-popup h3 {
  text-align: center;
  font-size: 1.3rem;
}
.users-distributor-popup .edit-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.users-distributor-popup .edit-form .form-wrapper,
.users-distributor-popup .edit-form .form-wrapper-select {
  /* background-color: #ffffff; */
  display: flex;
  /* margin: 20px auto 0 auto; */
  justify-content: center;
  flex-direction: column;

  padding: 10px;
  width: 60vw;
  max-width: 300px;
  min-width: 210px;
}

.users-distributor-popup .edit-form .form-wrapper input {
  height: 65px;
  width: 100%;
  background-color: rgba(242, 242, 242, 1);
  color: rgba(121, 121, 121, 1);
  border-radius: 12px 12px 0 0;
  border-bottom: 1px solid #000000;
  padding-inline-start: 20px;
  font-size: 1rem;
}
.users-distributor-popup .edit-form .form-wrapper label {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 0.8rem;
}
.users-distributor-popup .edit-form .form-wrapper label input {
  width: 20px;
  height: 20px;
}

.users-distributor-popup .edit-form .buttons {
  display: flex;
  padding: 5px 0;
  justify-content: space-evenly;
  align-items: center;
}
.users-distributor-popup .edit-form .buttons button {
  color: #ffffff;
  padding: 10px 15px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 55px;
  background-color: #cb2a27;
  font-size: 0.7rem;
}
.users-distributor-popup .edit-form .buttons button.delete {
  background-color: #858585;
}
@media (min-width: 500px) {
  .users-distributor-popup .edit-form .buttons button {
    /* padding: 15px 25px; */
    font-size: 1rem;
  }
  .users-distributor-popup h3 {
    font-size: 2rem;
  }
}
