.statistics-desktop-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: #ffffff;
}

.statistics-desktop-title .stat-title {
  font-size: 1.8rem;
}

.statistics-desktop-title .registered-users h3,
.registered-distributors h3 {
  font-size: 1.2rem;
}
.statistics-wrapper {
  width: 100%;
}

.statistics-wrapper .chart {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
/* .statistics-wrapper .chart canvas {
  width: 30%;
  height: 30%;
} */
.statistics-wrapper .chart .score {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.statistics-wrapper .chart .score .score-title h3 {
  color: #ffffff;
  font-size: 1.2rem;
}
/* 
.statistics-wrapper .chart .score .rating-desktop div .stars.react-stars > * {
  font-size: 3vw !important;
} */

.statistics-wrapper .finish-status {
  min-width: 60%;
  max-width: 450px;
  display: grid;
  background-color: #000000;
  color: #ffffff;
  grid-template-columns: repeat(2, 1fr);
  /* grid-template-rows: repeat(2, auto); */
  text-align: center;
  gap: 1rem;
  /* margin-left: 6%; */
  margin-top: 10%;
  padding: 20px 0;
}
.statistics-wrapper .finish-status .finish {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  padding: 0 20px;
}
.circle {
  background-color: white;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.not-started-circle {
  background-color: #f20b07;
}
.grey-circle {
  background-color: #8e8e8e;
}
.dark-red-circle {
  background-color: #860704;
}
.light-red-circle {
  background-color: #cb2a27;
}

.list-of-users {
  background-color: #000000;
  max-width: 450px;
  margin: 20px 0;
  padding: 20px;
  color: #ffffff;
  min-width: 60%;
}
.list-of-users h3 {
  text-align: center;
  font-size: 1.5rem;
}

.list-of-users .list-header {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 20px 0;
  font-size: 1.2rem;
}
.list-of-users .list-body {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Align vertically centered */
  margin-bottom: 20px;
  font-size: 1rem;
  border-bottom: 1px solid #ffffff;
}

.list-of-users .list-body .distributor-name {
  min-width: 60%;
  /* Adjust padding or margin as needed */
}

.list-of-users .list-body .distributor_completion {
  min-width: 40%;
  /* Adjust padding or margin as needed */
}
/* .list-of-distributors .list-body .distributor-name {
  padding-inline-start: 15%;
} */
.statistics-wrapper .lessons-with-bad-rating {
  margin-top: 20px;
  color: #ffffff;
  padding: 20px 0;
  min-width: 60%;
  background-color: #000000;

  max-width: 450px;
}
.statistics-wrapper .lessons-with-bad-rating h3 {
  font-size: 1.5rem;
  background-color: #000000;
  text-align: center;
  padding: 20px 0;
  padding-top: 20px;
}
.statistics-wrapper .lessons-with-bad-rating .list-header,
.statistics-wrapper .lessons-with-bad-rating .list-body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Change this to align-items: flex-start */
  padding: 10px 20px;
}

.statistics-wrapper .lessons-with-bad-rating .list-header span,
.statistics-wrapper .lessons-with-bad-rating .list-body span {
  flex: 1;
  text-align: center;
  font-size: 1rem;
}

/* .statistics-wrapper .lessons-with-bad-rating .list-body .votes {
  padding-right: 20px;
}
.statistics-wrapper .lessons-with-bad-rating .list-body .lesson-title {
  padding-left: 20px;
} */

/*MOBILE from  here */

.admin-mobile-wrapper {
  margin-top: 20px;
}

.mobile-statistics-wrapper {
  width: 80%;
  margin: 0 auto;
}
.mobile-statistics-wrapper .mobile-chart .doughnut-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-statistics-wrapper .finish-status {
  display: grid;
  max-width: 450px;
  background-color: #000000;
  color: #ffffff;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  text-align: center;
  gap: 1rem;
  margin-top: 8%;
  padding: 10px 0;
}
.mobile-statistics-wrapper .finish-status .finish {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  padding: 0 10px;
}
.mobile-statistics-wrapper .score {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mobile-statistics-wrapper .score .score-title h3 {
  font-size: 1.6rem;
}
/* .mobile-statistics-wrapper .score .score-title {
} */
.mobile-statistics-wrapper .score .rating-desktop {
  margin-top: 10px;
}

.mobile-statistics-wrapper .score .rating-desktop div .stars.react-stars > * {
  font-size: 4vw !important;
}

.mobile-statistics-wrapper .lessons-with-bad-rating-mobile {
  color: #ffffff;
  background-color: #000000;
  padding: 10px;
  margin: 20px 0;
}
.mobile-statistics-wrapper .registered-users-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline-start: 10px;
  justify-content: space-between;
  color: #ffffff;
  padding: 10px;
  margin: 20px 0;
}
.mobile-statistics-wrapper .lessons-with-bad-rating-mobile h3 {
  margin-bottom: 20px;
  font-size: 1.3rem;
}
.mobile-statistics-wrapper .lessons-with-bad-rating-mobile .list-header,
.mobile-statistics-wrapper .lessons-with-bad-rating-mobile .list-body {
  display: flex;
  justify-content: space-evenly;
}

.mobile-statistics-wrapper .lessons-with-bad-rating-mobile .list-header span,
.mobile-statistics-wrapper .lessons-with-bad-rating-mobile .list-body span {
  flex: 1;
  text-align: center;
  font-size: 1rem; /* Adjust font size as needed */
}

@media (max-width: 450px) {
  .mobile-statistics-wrapper .lessons-with-bad-rating-mobile h3 {
    font-size: 0.9rem;
  }
  .mobile-statistics-wrapper .lessons-with-bad-rating-mobile .list-header span,
  .mobile-statistics-wrapper .lessons-with-bad-rating-mobile .list-body span {
    font-size: 0.8rem;
  }
}

@media (max-width: 360px) {
  .mobile-statistics-wrapper .lessons-with-bad-rating-mobile h3 {
    font-size: 0.8rem;
    margin-bottom: 20px;
  }
  .mobile-statistics-wrapper .lessons-with-bad-rating-mobile .list-header span,
  .mobile-statistics-wrapper .lessons-with-bad-rating-mobile .list-body span {
    font-size: 0.7rem;
  }
}

@media (max-width: 1030px) {
  .statistics-wrapper .chart .score .score-title h3 {
    font-size: 1rem;
  }

  /* .statistics-wrapper .finish-status {
    width: 60%;
  } */
  .statistics-wrapper .chart .score .rating-desktop div .stars.react-stars > * {
    font-size: 2.5vw !important;
  }
}
@media (max-width: 830px) {
  .statistics-wrapper .chart .score .score-title h3 {
    font-size: 0.7rem;
  }
  /* .statistics-wrapper .chart .doughnut-wrapper canvas {
    width: 20%;
    height: 20%;
  } */
  /* .statistics-wrapper .finish-status {
    width: 70%;
  } */
}
