input.hidden {
  display: none;
}
.message-form {
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: inherit;
}

.message-form .close-popup {
  align-self: flex-end;
  margin: 10px;
}
.message-form .form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60vw;
  margin-top: 15px;
}
.message-form .form-wrapper div.upload {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
}
.message-form-wrapper .message-form div input,
.message-form-wrapper .message-form div textarea {
  font-size: 1.2rem;
  padding-inline-start: 20px;
  border-radius: 12px 12px 0 0;
  height: 55px;
  width: 40vw;
  background-color: #f2f2f2;
  border-bottom: 1px solid #000000;
}
.message-form-wrapper .message-form div textarea {
  height: 13vh;
}
.message-form div.submit {
  display: flex;
  justify-content: flex-end;
}
.message-form div.submit input[type="submit"] {
  background-color: #cb2a27;
  border: none;
  border-radius: 55px;
  color: #ffffff;
  max-width: 80%;
  margin: 0 auto;
  height: 42px;
  padding-inline-start: 0;
}
/* .message-form div textarea {
  width: 78%;
} */
.message-form div.file-upload {
  background-color: rgba(121, 121, 121, 1);
  width: 55%;
  margin-inline-start: 12%;
  margin-top: 20px;
  padding: 2% 4%;
  border-radius: 12px;
}
.message-form div input,
.message-form div textarea {
  font-size: 0.8rem;
}

@media (min-width: 1100px) {
  .message-form-wrapper .message-form div input,
  .message-form-wrapper .message-form div textarea {
    width: 24vw;
    /* here */
  }
}

@media (max-width: 1250px) {
  .message-form div.file-upload {
    width: 75%;
    font-size: 0.8rem;
  }
  .board .information .unpinned .unpinned-wrapper .headline {
    width: 78%;
    font-size: 0.9rem;
  }
}
