.user-mobile-navbar {
  background-color: #cb2a27;
  display: flex;
  justify-content: center;
  gap: 14%;
  align-items: center;
  padding: 30px 0;
  border-radius: 0 0 12px 12px;
  z-index: 100;
}
/* 
  
  .super-admin-container .mobile-navbar div img,
  .admin-home-page-mobile .mobile-navbar div img,
  .admin-home-page-mobile-community .mobile-navbar div img {
    width: 20vw;
  } */

.user-mobile-navbar div img {
  width: 18vw;
}

.user-mobile-navbar div img.logo-center {
  width: 25vw;
}
/* .super-admin-container .mobile-navbar div img.logo-center,
  .admin-home-page-mobile .mobile-navbar div img.logo-center,
  .admin-home-page-mobile-community .mobile-navbar div img.logo-center {
    width: 25vw;
  } */

/* Position and sizing of burger button */
.bm-burger-button {
  position: relative;
  width: 26px;
  height: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
}

/* Color/shape of burger icon bars on hover*/
/* .bm-burger-bars-hover {
    background: #a90000;
  } */

/* Position and sizing of clickable cross button */
.bm-menu-wrap.user {
  height: 12px;
  width: 12px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #ffffff;
}

/* General sidebar styles */
.bm-menu-wrap.user .bm-menu {
  cursor: pointer;
  background: rgba(134, 7, 4, 1);
  padding: 0 20%;
  padding-top: 100px;
}

/* Morph shape necessary with bubble or elastic */
/* .bm-morph-shape {
  fill: #373a47;
} */

/* Wrapper for item list */
.bm-menu-wrap.user .bm-item-list {
  color: #000000;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Individual item */
.bm-menu-wrap.user .bm-item {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  /* color: #ffffff;
  font-size: 1.2rem; */
  /* font-family: "Heebo", sans-serif; */
}

.bm-item:not(:last-child) {
  border-bottom: 1px solid #ffffff;
}
.bm-menu-wrap.user .menu-item {
  width: 80%;
  height: 100px;
  background: #000000;
  border-radius: 12px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin: 20px 0;

  text-align: center;
  font-size: 0.8rem;
}
.bm-item .menu-item:first-child {
  gap: 10px;
}
.bm-item .menu-item:not(:first-child) {
  padding: 17px 25px;
}
.bm-item .menu-item:last-child {
  padding: 25px 25px;
}
/* Styling of overlay */
.bm-overlay {
  top: 0;
  left: 0;
}

@media (min-width: 370px) {
  .bm-item .menu-item {
    width: 70%;
  }
}
