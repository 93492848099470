.question-container,
.edit-quiz-question-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 10px;
  border-bottom: 1px solid #000000;
}

.edit-quiz-question-container .question-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  font-weight: 600;
  font-size: 1.2rem;
}
.edit-quiz-question-container .question-title .trash {
  cursor: pointer;
}
/* question container */
.question-container form .form-wrapper-question,
.edit-quiz-question-container form .form-wrapper-edit-question {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.question-container form .form-wrapper-question input,
.edit-quiz-question-container form .form-wrapper-edit-question input {
  /* border: 1px solid red; */
  width: 100%;
  background: #f2f2f2;
  border-radius: 12px 12px 0px 0px;
  padding-inline-start: 5%;
  margin-top: 10px;
  height: 55px;
}
.edit-quiz-popup-wrapper {
  cursor: pointer;
}
.edit-quiz-popup-wrapper .confirm-quiz-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
}

.edit-quiz-popup-wrapper .quizEdit-buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.edit-quiz-popup-wrapper .quizEdit-buttons .update-btn button,
.edit-quiz-popup-wrapper .quizEdit-buttons .delete-quiz button {
  background-color: #cb2a27;
  padding: 12px 50px;
  border-radius: 55px;
  color: #ffffff;
  margin-bottom: 20px;
}
.edit-quiz-popup-wrapper .quizEdit-buttons .delete-quiz button {
  background-color: #858585;
}

/* answer container for create quiz popup */

.question-container form .form-wrapper-answer {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin-bottom: 10px;
}

/* answer container for edit quiz popup */
.edit-quiz-question-container form .form-wrapper-edit-answer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding-inline-end: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.question-container form .form-wrapper-answer .answer-input {
  width: 75%;
  display: flex;
}
.edit-quiz-question-container form .form-wrapper-edit-answer .answer-input {
  width: 55%;
  display: flex;
}

.edit-quiz-question-container form .form-wrapper-edit-answer .answer-correct {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 35%;
}
.edit-quiz-question-container
  form
  .form-wrapper-edit-answer
  .answer-correct
  label {
  font-size: 0.8rem;
}
.edit-quiz-question-container
  form
  .form-wrapper-edit-answer
  .answer-input
  input,
.question-container form .form-wrapper-answer .answer-input input {
  flex-grow: 2;
  background: #f2f2f2;
  border-radius: 12px 12px 0px 0px;
  padding-inline-start: 5%;
  margin-top: 10px;
  height: 55px;
}

.question-container form .form-wrapper-answer .answer-correct {
  width: 30%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.question-container form .form-wrapper-answer .answer-correct label {
  font-size: 0.8rem;
}

.question-container form .add-answer {
  margin-bottom: 20px;
  cursor: pointer;
}

.question-container .quiz-submit {
  display: flex;
  justify-content: center;
  align-items: center;
}
.question-container .quiz-submit button {
  background-color: #cb2a27;
  border-radius: 55px;
  color: #ffffff;
  width: 40%;
  margin: 0 auto;
  height: 40px;
}
