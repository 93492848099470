/* DESKTOP VIEW ADMIN && SUPER ADMIN */
.super-admin-home-page,
.admin-home-page {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url("../../img/home-page-background.png") no-repeat center center;
  background-size: cover;
  z-index: 10;
}

.main-content-container {
  display: flex;
  padding: 0 50px;
  gap: 50px;
  margin-top: 30px;
  height: 76vh;
}

/* Community && Users && Statistics Tabs DESKTOP */
.board {
  background-color: rgba(203, 42, 39, 0.7);
  border-radius: 12px;
  display: flex;
  width: 60%;
  height: 70vh;
  justify-content: space-between;
  align-items: center;
  padding: 20px 35px;
  overflow-y: scroll;
}
.board-black {
  border-radius: 12px;
  width: 60%;
  height: 70vh;
  padding: 20px 35px;
  overflow-y: scroll;
}
.board::-webkit-scrollbar,
.board-black::-webkit-scrollbar {
  width: 3px;
}
.board::-webkit-scrollbar-track,
.board-black::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.board::-webkit-scrollbar-thumb,
.board-black::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 10px;
}

/* Statistics Tab background color   */

.board-black {
  background-color: rgba(17, 17, 17, 0.7);
}

.board .message {
  background-color: #ffffff;
  width: 30%;
  height: 88%;
  border-radius: 12px;
}
.board .message div {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 10px;
}
.board .message div h3 {
  font-size: 1.3rem;
  font-weight: 700;
}

/* MOBILE DESIGN */

/* BACKGROUND IMAGE FOR MOBILE VIEW - STATISTICS && USERS */
.admin-home-page-mobile {
  position: relative;
  width: 100%;
  height: 90vh;
  /* background: url("../../img/home-page-background.png") no-repeat center center; */
  background-size: cover;
  border-radius: 0 0 12px 12px;
  z-index: 10;
}

/* BACKGROUND IMAGE FOR MOBILE VIEW - COMMUNITY */
.admin-home-page-mobile-community {
  position: relative;
  width: 100%;

  /* background: url("../../img/home-page-background.png") no-repeat center center; */
  /* background-size: cover; */
  /* border-radius: 0 0 12px 12px; */
}
.admin-home-page-mobile-community .mobile-community-top {
  position: relative;
  width: 100%;
  height: 100vh;
  border-radius: 0 0 12px 12px;
  z-index: 10;
  overflow-y: scroll;
}

.admin-home-page-mobile-community .mobile-community-top::-webkit-scrollbar {
  width: 3px;
}
.admin-home-page-mobile-community
  .mobile-community-top::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.admin-home-page-mobile-community
  .mobile-community-top::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 10px;
}
/* Search bar Wrapper */
.admin-home-page-mobile-community .mobile-community-top .search {
  display: flex;
  justify-content: center;
}

.admin-home-page-mobile-community .mobile-community-bottom {
  background-color: red;
  width: 100%;
  height: 50vh;
  position: relative;
  top: -10px;
}
/* ******************************************** */

/* .admin-mobile-wrapper .notifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 10% 0 10%;
}

.admin-mobile-wrapper .notifications p {
  margin-bottom: 0px;
  font-size: 0.8rem;
}
.admin-mobile-wrapper .notifications .animal-wrapper {
  margin-top: 10px;
}
.admin-mobile-wrapper .notifications .data-wrapper div {
  height: 45px;
}

.admin-mobile-wrapper .notifications .data-wrapper div img {
  bottom: 12px;
}
.admin-mobile-wrapper .notifications .data-wrapper div span {
  font-size: 1.1rem;
} */

.admin-home-page-mobile h3 {
  color: #ffffff;
  text-align: center;
  font-size: 1.2rem;
  margin-top: 15px;
  margin-bottom: 0;
}
/* 
.admin-home-page-mobile .invite-member-form {
  gap: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}

.admin-home-page-mobile .invite-member-form div {
  width: 70%;
  max-width: 350px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.admin-home-page-mobile .invite-member-form div input.invite-member-email {
  background-color: #535353;
  padding: 20px 30px;
  color: #ffffff;
  border-bottom: 2px solid #ffffff;
  height: 50px;
  width: 100%;
  border-radius: 12px 12px 0 0;
}
.admin-home-page-mobile .invite-member-form div input::placeholder {
  color: #ffffff;
  font-size: 1.3rem;
}

.admin-home-page-mobile .invite-member-form div input.submit-btn {
  background-color: #cb2a27;
  color: #ffffff;
  border-radius: 55px;
  padding: 15px 35px;
  width: 80%;
} */
