.information {
  position: relative;
  align-self: flex-start;
  width: 100%;
}
.board .information h3 {
  font-size: 26px;
  color: #ffffff;
  font-weight: 500;
  margin: 0;
}

.board .information .create-message-btn .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  max-width: 500px;
  margin: 20px auto;
  padding: 10px 0;
  border-radius: 55px;
  background-color: #cb2a27;
  max-width: 300px;
}
.board .information .create-message-btn .btn .create-message {
  font-size: 1.2rem;
}
.board .information .message-form-wrapper {
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;

  /* margin: 20px auto 0 auto; */
  justify-content: center;
  padding: 20px;
  width: 100%;
  margin: 0 auto;
}
.board .information .message-form-wrapper .form-wrapper {
  margin: 10px 0;
}

/* create dotted line around text  for desktop only*/
.board .separator {
  display: flex;
  color: #ffffff;
}

.separator span {
  position: relative;
  top: 10px;
  padding: 0 10px;
}

/* MOBILE */
/* .admin-home-page-mobile-community .mobile-community-top {
} 
*/
/* .user-mobile-wrapper .information {
} */
.admin-home-page-mobile-community .information,
.user-mobile-wrapper .information,
.user-community-mobile .information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

.admin-home-page-mobile-community .information .create-message-btn {
  margin: 15px 0;
  width: 75%;
  max-width: 65vw;
}
.admin-home-page-mobile-community .information .create-message-btn .btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #cb2a27;
  border-radius: 55px;
}

.admin-home-page-mobile-community .information .create-message-btn .btn h3 {
  font-size: 1rem;
  color: #ffffff;
  margin: 0;
  padding: 6px 0;
}

.admin-home-page-mobile-community .separator span {
  position: relative;
  padding: 0 10px;
}

.admin-home-page-mobile-community .pinned div .line,
.admin-home-page-mobile-community .unpinned .unpinned-wrapper .line {
  background-color: rgba(165, 165, 165, 1);
  width: 100%;
  height: 1px;
  margin: 0 auto 2% auto;
}

.admin-home-page-mobile-community .information .community_bottom {
  background-color: #cb2a27;
  width: 100%;
}

.admin-home-page-mobile-community
  .information
  .pinned
  .pinned-wrapper
  .headline,
.admin-home-page-mobile-community
  .information
  .unpinned
  .unpinned-wrapper
  .headline {
  font-weight: 700;
  font-size: 1rem;
}
.admin-home-page-mobile-community .pinned .article {
  font-size: 0.8rem;
  width: 90%;
  font-weight: 500;
}

.admin-home-page-mobile-community
  .information
  .pinned
  .pinned-wrapper
  .date-location,
.admin-home-page-mobile-community
  .information
  .unpinned
  .unpinned-wrapper
  .date-location {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  margin: 10px 0;
}

@media (min-width: 1100px) {
  .board .information .message-form-wrapper {
    width: 70%;
  }
}

@media (max-width: 1250px) {
  .board .information .unpinned .unpinned-wrapper .main .article {
    font-size: 0.8rem;
  }

  .board .information .pinned .pinned-wrapper .main .article {
    width: 70%;
  }
}
@media (min-width: 1400px) {
  .board .information .message-form-wrapper {
    width: 60%;
  }
}
