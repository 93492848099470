.file-container {
  background-color: #ffffff;
  margin: 20px;
  padding: 10px 50px;
  border-radius: 12px;
}

.file-container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.file-container .header .title {
  color: #000000;
}
.file-container .body {
  padding: 20px 0;
}
.file-container .footer {
  margin-bottom: 20px;
}
.file-container .footer button {
  background-color: #620402;
  border-radius: 55px;
  color: #ffffff;
  padding: 10px 20px;
}
