.edit-quiz-popup-wrapper,
.create-quiz-popup-wrapper {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  background-color: #ffffff;
  padding: 20px;
  width: 80vw;
  height: 95vh;
  max-width: 500px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  /* cursor: pointer; */
  z-index: 100;
  overflow-y: scroll;
}
.edit-quiz-popup-wrapper::-webkit-scrollbar,
.create-quiz-popup-wrapper::-webkit-scrollbar {
  width: 3px;
}
/* create popup and edit popup  both styles here different classes in beginning*/
.edit-quiz-popup-wrapper .header,
.create-quiz-popup-wrapper .header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
}
.edit-quiz-popup-wrapper .header .title,
.create-quiz-popup-wrapper .header .title {
  margin: 10px 0 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 2 / 3;
  font-size: 2rem;
}
.edit-quiz-popup-wrapper .header .close,
.create-quiz-popup-wrapper .header .close {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-column: 3 / 3;
  cursor: pointer;
}

/* .create-quiz-popup-wrapper .body {
  overflow-y: scroll;
} */
.edit-quiz-popup-wrapper .body .add-question,
.create-quiz-popup-wrapper .body .add-question {
  margin-top: 20px;
  cursor: pointer;
}
.create-quiz-popup-wrapper .body .quiz-submit {
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-quiz-popup-wrapper .body .quiz-submit button {
  background-color: #cb2a27;
  border-radius: 55px;
  color: #ffffff;
  width: 40%;
  margin: 0 auto;
  height: 40px;
}
