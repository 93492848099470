.error {
  color: #cb2a27;
  display: block;
  margin-top: 10px;
  font-size: 15px;
}

.admin-home-page-mobile .invite-member-form {
  gap: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}

.admin-home-page-mobile .invite-member-form div {
  width: 70%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.admin-home-page-mobile .invite-member-form div input.invite-member-email {
  background-color: #535353;
  padding: 20px 30px;
  color: #ffffff;
  border-bottom: 2px solid #ffffff;
  height: 50px;
  width: 100%;
  border-radius: 12px 12px 0 0;
}

.admin-home-page-mobile .invite-member-form div input::placeholder {
  color: #ffffff;
  font-size: 1.3rem;
}

.admin-home-page-mobile .invite-member-form div input.submit-btn {
  background-color: #cb2a27;
  color: #ffffff;
  border-radius: 55px;
  padding: 15px 35px;
  width: 80%;
}
/* admin mobile invite member styles  */

.invite-member-admin-mobile {
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}
.invite-member-admin-mobile .form-wrapper {
  width: 85%;
  max-width: 420px;
  margin-bottom: 15px;
}
.invite-member-admin-mobile .form-wrapper input::placeholder {
  color: #ffffff;
}
.invite-member-admin-mobile .form-wrapper input,
.invite-member-admin-mobile .form-wrapper select {
  height: 8vh;
  width: 100%;
  border-radius: 12px 12px 0 0;
  padding-inline-start: 20px;
  color: #ffffff;
  background-color: #535353;
}
.invite-member-admin-mobile .form-btn .submit-btn {
  background-color: #cb2a27;
  padding: 10px 30px;
  border-radius: 55px;
  color: #ffffff;
}

/*   */

/* super admin mobile invite member styles */
.super-invite-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  margin-top: 20px;
}
.super-invite-member div {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.super-invite-member input.invite-user,
.super-invite-member select.invite-user {
  background: rgba(181, 76, 76, 1);
  padding: 20px 30px;
  color: #ffffff;
  border-bottom: 2px solid #ffffff;
  height: 60px;
  min-width: 238px;
  width: 70vw;
  max-width: 500px;
  border-radius: 12px 12px 0 0;
  font-size: 1rem;
}
.super-invite-member input.invite-user::placeholder {
  color: #ffffff;
  font-size: 1.3rem;
}

.super-invite-member .form-btn input[type="submit"] {
  padding: 15px 20px;
  background-color: black;
  color: #ffffff;
  border-radius: 55px;
  width: 65vw;
}

/* ends here */
