.user-search-wrapper {
  position: fixed;
  width: 500px;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.user-search-wrapper .search-form {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.user-search-wrapper .search-form .search-control {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.search-form .search-control label {
  color: #ffffff;
  font-size: 30px;
}

.search-form .search-control input {
  background-color: transparent;
  width: 55%;
  margin-inline-start: 10px;
  color: #ffffff;
  border-bottom: 2px solid rgba(255, 255, 255, 1);
}

.user-search-wrapper .search-form .search-control-checkbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1%;
  color: #ffffff;
  font-size: 14px;
}

.search-control-checkbox input {
  border: 1px solid red;
}
.search-control-checkbox span {
  display: inline-block;
  padding-inline-end: 10px;
}
.user-search-wrapper .search-form .search-form-footer {
  width: 100%;
  padding-top: 15px;
  padding-inline-end: 21%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.user-search-wrapper .search-form .search-form-footer .error {
  align-self: center;
  font-size: 1.1rem;
  font-weight: 500;
}
.search-form .search-form-footer .search-btn {
  background-color: #cb2a27;
  padding: 8px 30px;
  border-radius: 55px;
  width: 150px;
  display: flex;
  color: #ffffff;
  justify-content: space-between;
}
