.board .information .pinned {
  margin-top: 20px;
  position: relative;
  overflow-y: scroll;
  z-index: 1;
}

.board .information .pinned::-webkit-scrollbar {
  display: none;
}

.board .information .pinned .pinned-wrapper,
.board .information .unpinned .unpinned-wrapper {
  background-color: #efefef;
  border-radius: 12px;
  margin-bottom: 10px;
  max-height: 190px;
  overflow-y: scroll;
}

.board .information .unpinned {
  margin-top: 5%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

/* make scrollbar transparent but still scroll */
.pinned .pinned-wrapper::-webkit-scrollbar,
.unpinned-wrapper::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.pinned-wrapper .unpin-icon,
.unpinned-wrapper .pinned-icon {
  display: flex;
  /* justify-content: flex-end; 
      the justify-content is set in the Messages component 
  */
  align-items: center;
  gap: 10px;
  font-size: 0.9rem;
  overflow-y: auto;
}

.pinned:hover,
.unpinned:hover {
  cursor: pointer;
}
.pinned-wrapper .admin-message-header,
.unpinned-wrapper .admin-message-header {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}
.pinned-wrapper .unpin-icon .message-header-pin,
.unpinned-wrapper .pinned-icon .message-header-pin {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.pinned-wrapper .unpin-icon .message-edit-delete,
.unpinned-wrapper .pinned-icon .message-edit-delete {
}

.pinned-wrapper .unpin-icon img,
.unpinned-wrapper .pinned-icon img {
  padding-left: 10px;
  width: 35px;
  height: auto;
}

.board .information .unpinned .unpinned-wrapper .pinned-icon,
.admin-home-page-mobile-community
  .information
  .unpinned
  .unpinned-wrapper
  .pinned-icon {
  font-size: 0.9rem;
}

.pinned-wrapper .date-location,
.unpinned-wrapper .date-location {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
}

.board .information .pinned .pinned-wrapper .headline,
.board .information .unpinned .unpinned-wrapper .headline {
  font-weight: 700;
  font-size: 1rem;
  width: 60%;
  margin: 5px 0;
}

.board .information .pinned .pinned-wrapper .main {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
}

.board .information .pinned .pinned-wrapper .main .article {
  font-size: 0.9rem;
  font-weight: 400;
  width: 70%;
}

.pinned-wrapper .main .article-btn,
.unpinned-wrapper .main .article-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pinned-wrapper .main .article-btn button,
.unpinned-wrapper .main .article-btn button {
  background-color: #620402;
  padding: 5px 12px;
  border-radius: 55px;
  color: #ffffff;
  font-size: 1rem;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pinned-wrapper .main .article-btn .files-download,
.unpinned-wrapper .main .article-btn .files-download {
  display: flex;
  justify-content: center;
  gap: 3px;
  align-items: center;
}
/* .board .information .unpinned .unpinned-wrapper .main,
.admin-home-page-mobile-community
  .information
  .unpinned
  .unpinned-wrapper
  .main {
  width: 80%;
} */
.board .information .unpinned .unpinned-wrapper .main .article {
  font-size: 1rem;
}
.board .information .pinned .pinned-wrapper .main .article {
  width: 78%;
}
.pinned div .line,
.unpinned .unpinned-wrapper .line {
  background-color: rgba(165, 165, 165, 1);
  width: 100%;
  height: 1px;
  margin: 2% auto 2% auto;
}
.unpinned .unpinned-wrapper .line {
  margin: 5% auto 2% auto;
}
.board .information .unpinned .unpinned-wrapper .pinned-icon {
  gap: 5px;
  justify-content: flex-end;
  padding: 2% 0;
}
.board .separator {
  display: flex;
  color: #ffffff;
  cursor: pointer;
}
.board .separator:after,
.board .separator:before {
  border-bottom: 1px dashed #ffffff;
  content: "";
  flex: 1;
}

.separator span {
  position: relative;
  top: 10px;
  padding: 0 10px;
}
.board .pinned .pinned-wrapper .message-edit-delete,
.admin-home-page-mobile-community
  .information
  .pinned-wrapper
  .message-edit-delete {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
/* MOBILE */
/* .admin-home-page-mobile-community .mobile-community-top {
} 
*/
.community_bottom .unpinned .no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  color: #ffffff;
  font-size: 1.5rem;
}
.admin-home-page-mobile-community .information .pinned,
.user-mobile-wrapper .information .pinned,
.user-community-mobile .information .pinned {
  width: 100%;
  max-height: 320px;
  position: relative;
  /* z-index: 1000; */
  overflow-y: scroll;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  border: none;
  margin-bottom: 10%;
}
.pinned::-webkit-scrollbar,
.unpinned::-webkit-scrollbar {
  display: none;
}
.admin-home-page-mobile-community .information .unpinned,
.user-mobile-wrapper .information .unpinned,
.user-community-mobile .information .unpinned {
  position: relative;
  background-color: #cb2a27;
}

.admin-home-page-mobile-community .information .unpinned h3,
.user-mobile-wrapper .information .unpinned h3,
.user-community-mobile .information h3 {
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  font-size: 1.2rem;
  margin-top: 15px;
  margin-bottom: 0;
  padding-top: 20px;
}

.admin-home-page-mobile-community .information .pinned .pinned-wrapper,
.admin-home-page-mobile-community .information .unpinned .unpinned-wrapper,
.user-mobile-wrapper .information .pinned-wrapper,
.user-mobile-wrapper .information .unpinned-wrapper,
.user-community-mobile .information .pinned-wrapper,
.user-community-mobile .information .unpinned-wrapper {
  background-color: #efefef;
  border-radius: 12px;
  margin-bottom: 10px;
  padding: 2% 7%;
  margin: 20px 15%;
  max-height: 250px;
  overflow-y: scroll;
}
/* when user clicks on a message we remove max height */
.admin-home-page-mobile-community
  .information
  .pinned
  .pinned-wrapper.no-max-height,
.admin-home-page-mobile-community
  .information
  .unpinned
  .unpinned-wrapper.no-max-height,
.user-mobile-wrapper .information .pinned .pinned-wrapper.no-max-height,
.user-mobile-wrapper .information .unpinned .unpinned-wrapper.no-max-height,
.user-community-mobile .information .pinned .pinned-wrapper.no-max-height,
.user-community-mobile .information .unpinned .unpinned-wrapper.no-max-height {
  max-height: none;
}
.admin-home-page-mobile-community .separator span,
.user-community-mobile .separator span {
  position: relative;
  padding: 0 10px;
}

.admin-home-page-mobile-community .pinned div .line,
.admin-home-page-mobile-community .unpinned .unpinned-wrapper .line,
.user-community-mobile .pinned div line,
.user-community-mobile .unpinned .unpinned-wrapper .line {
  background-color: rgba(165, 165, 165, 1);
  width: 100%;
  height: 1px;
  margin: 5% auto 2% auto;
}

.admin-home-page-mobile-community .information .community_bottom,
.user-community-mobile .information .community_bottom {
  background-color: #cb2a27;
  position: relative;
  bottom: -50px;
  width: 100%;
}

.admin-home-page-mobile-community .information .community_bottom.dark-mode,
.user-community-mobile .information .community_bottom.dark-mode {
  filter: brightness(30%);
  background-color: rgba(46, 40, 43, 0.8);
  pointer-events: none;
}

.pinned-wrapper .headline,
.unpinned-wrapper .headline {
  font-weight: 700;
  padding: 10px 0;
  font-size: 1rem;
}
.admin-home-page-mobile-community .pinned .article,
.admin-home-page-mobile-community .unpinned .article,
.user-community-mobile .pinned .article,
.user-community-mobile .unpinned .article {
  font-size: 0.8rem;
  width: 90%;
  font-weight: 500;
}

.admin-home-page-mobile-community
  .information
  .pinned
  .pinned-wrapper
  .date-location,
.admin-home-page-mobile-community
  .information
  .unpinned
  .unpinned-wrapper
  .date-location,
.user-community-mobile .information .pinned .pinned-wrapper .date-location,
.user-community-mobile .information .unpinned .unpinned-wrapper .date-location {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  margin: 10px 0;
}

@media (max-width: 1250px) {
  .board .information .unpinned .unpinned-wrapper .main .article {
    font-size: 0.8rem;
  }

  .board .information .pinned .pinned-wrapper .main .article {
    width: 70%;
  }
}
@media (max-width: 1100px) {
  .board .information .pinned .pinned-wrapper .main .article {
    width: 60%;
  }
  .unpinned-wrapper .main .article-btn button {
    font-size: 0.7rem;
  }
}

@media (min-width: 280px) and (max-width: 420px) {
  .admin-home-page-mobile-community
    .information
    .pinned
    .pinned-wrapper
    .date-location,
  .admin-home-page-mobile-community
    .information
    .unpinned
    .unpinned-wrapper
    .date-location,
  .user-community-mobile .information .pinned .pinned-wrapper .date-location,
  .user-community-mobile
    .information
    .unpinned
    .unpinned-wrapper
    .date-location {
    font-size: 0.6rem;
  }

  .pinned-wrapper .main .article-btn button,
  .unpinned-wrapper .main .article-btn button {
    font-size: 0.8rem;
  }
}
@media (min-width: 741px) {
  .board .information .pinned .pinned-wrapper .main {
    flex-direction: column;
    align-items: inherit;
  }
}
