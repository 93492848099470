.user-accordion-wrapper {
  height: 60vh;
  overflow-y: scroll;
  padding: 20px;
  margin-bottom: 90px;
}

.user-accordion-wrapper::-webkit-scrollbar {
  width: 0px;
}

.user-accordion-wrapper .accordion {
  margin-top: 15px;
}

.user-accordion-wrapper .section-progress-mobile {
  background-color: #000000;
  width: 100%;
  height: 6px;
  margin-bottom: 10px;
}

.user-accordion-wrapper .section-progress-mobile .section-bar {
  background-color: #ffffff;
  height: 6px;
}
/* .user-accordion-wrapper .completed {
  color: #ffffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
} */

.user-accordion-wrapper .accordion .user-accordion-item.accordion-borderless {
  border: none;
}
/* style accordion trigger when closed */
.user-accordion-wrapper .accordion-button[aria-expanded="true"],
.user-accordion-wrapper .accordion-button[aria-expanded="false"] {
  background-color: red;
  border-radius: 7px 7px 0 0 !important;
  color: #ffffff;
  box-shadow: none;
}
/* style accordion trigger when open */
/* .user-accordion-wrapper .accordion-button:focus {
  background-color: red;
  box-shadow: none;
  color: #ffffff;
} */
.user-accordion-wrapper .accordion-header button div .line {
  display: inline-block;
  position: relative;
}

.user-accordion-wrapper .accordion-header button div .line::after {
  display: inline-block;
  position: relative;
  top: 4px;
  left: 10px;
  content: "";
  width: 1px;
  background-color: #ffffff;
  height: 20px;
}

.user-accordion-wrapper .accordion-header button div span {
  margin: 0 10px;
  /* display: flex; */
}
.user-accordion-wrapper .accordion-body {
  background-color: #000000;
  color: #ffffff;
  cursor: pointer;
}
.user-accordion-wrapper .accordion-body .inner-section {
  display: flex;
  gap: 20px;
}

.user-accordion-wrapper .accordion-body .inner-section .video-number {
  display: flex;
  align-items: center;
}

.user-accordion-wrapper .accordion-body .inner-section .video-details {
  display: flex;
  border-bottom: 1px solid #ffffff;
  flex-grow: 2;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

/* .user-accordion-wrapper
  .accordion-body
  .inner-section
  .video-details
  .video-title-wrapper {
  display: flex;
} */

.user-accordion-wrapper
  .accordion-body
  .inner-section
  .video-details
  .play-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.user-accordion-wrapper .accordion-body .inner-section .check {
  display: flex;
  align-items: center;
}
.user-accordion-wrapper .accordion-body .inner-section .video-title {
  margin-inline-start: 10px;
}

.mobile-next-video-btn {
  background-color: #000000;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobile-next-video-btn button {
  background-color: #cb2a27;
  color: #ffffff;
  border-radius: 55px;
  padding: 10px 40px;
}
@media (max-width: 750px) {
  .user-accordion-wrapper .completed {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    color: #ffffff;
    align-items: center;
  }
}
