@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Heebo", sans-serif;
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  background: url("./Components/img/home-page-background.png") no-repeat center
    center;
  background-size: cover;
}

.App-dark {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  filter: brightness(30%);
  background-color: rgba(46, 40, 43, 0.8);
  pointer-events: none;
}
/* user desktop view */
.user-desktop-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.user-mobile-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  background: url("./Components/img/super-admin/mobile-background.png")
    no-repeat center center;
  background-size: cover;
}
.show-popup {
  position: absolute;
  width: 100vw;
  height: 126vh;
  top: 0;
  left: 0;
  background-color: rgba(17, 17, 17, 0.7);
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.show-popup .popUp-container {
  background-color: #ffffff;
  width: 80%;
  height: 80vh;
  margin-bottom: 50px;
  border-radius: 12px;
  position: fixed;
  bottom: 10px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.show-popup .popUp-container .close {
  align-self: flex-end;
}
.show-popup .popUp-container .message-form {
  align-self: center;
}
/* .show-popup .popUp-container .message-form div {
  width: 60vw;
  margin-top: 15px;
} */
.show-popup .popUp-container .message-form div input,
.show-popup .popUp-container .message-form div textarea {
  width: 100%;
  height: 55px;
  background-color: #f2f2f2;
  border-radius: 12px 12px 0 0;
  border-bottom: 1px solid #000000;
  padding-inline-start: 20px;
  font-size: 1rem;
}
.show-popup .popUp-container .message-form div textarea {
  height: 100px;
}
.show-popup .popUp-container .message-form div.file-upload {
  font-size: 1rem;
  width: 70%;
  display: flex;
  justify-content: flex-start;
}
.show-popup .popUp-container .message-form div.file-upload .upload {
  width: 100%;
  padding: 0 10px 5px 10px;
}
