.message-result {
  background-color: #ffffff;
  padding: 20px 30px;
  border-radius: 12px;
  width: 70%;
  margin: 20px;
}
.message-result .message-header {
  display: flex;
  justify-content: space-between;
}
.message-result .message-body {
}
.message-result .message-body .message-title {
  font-weight: 700;
  margin: 10px 0;
}
.message-result .message-body .message-btn button {
  background-color: #620402;
  padding: 5px 12px;
  border-radius: 55px;
  color: #ffffff;
  font-size: 1rem;
  margin-top: 10px;
  margin-bottom: 10px;
}
