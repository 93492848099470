.super-admin-container {
  /* background: url("../../img/super-admin/mobile-background.png") no-repeat
    center center;
  background-size: cover; */
  min-height: 110vh;
  width: 100%;
  z-index: 100;
  border-radius: 0 0 12px 12px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3));
}

.super-admin-container .super-main-notifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.super-main-form-wrapper h3,
.super-main-member-form h3 {
  color: #ffffff;
  text-align: center;
  margin: 20px 0;
  font-weight: 400;
}

.super-main-member-form h3.super-bottom-title {
  margin-top: 50px;
  cursor: pointer;
}

.super-main-member-form {
  background: rgba(134, 7, 4, 1);
  position: relative;
  top: -10px;
  border-radius: 0 0 12px 12px;
  z-index: 90;
  margin-bottom: -15px;
}
