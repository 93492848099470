nav.navbar {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

/* admin && super admin view */

nav.navbar .nav-left {
  display: flex;
  justify-content: flex-start;
  margin-inline-start: 20px;
}

nav.navbar .nav-left img.nav-logo,
nav.navbar .nav-right img.nav-logo {
  margin-top: 10px;
  width: 100px;
}

nav.navbar .nav-right {
  margin-inline-end: 20px;
  display: flex;
  justify-content: flex-end;
}

nav.navbar .nav-center {
  display: flex;
  width: 75%;
  gap: 30px;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 10px;
}
nav.navbar .nav-center button,
nav.navbar .nav-center .sign_out {
  color: #ffffff;
  background-color: #cb2a27;
  /* width: 200px; */
  padding: 7px 10px;
  border-radius: 55px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
}
nav.navbar .nav-center .sign_out:hover {
  background-color: #ffffff;
  color: #000000;
}
nav.navbar .nav-center .sign_out span,
nav.navbar .nav-center button span {
  font-size: 20px;
}
/* nav.navbar .nav-center button span {
  display: block;
  font-size: 1.3em;
} */
nav.navbar .nav-center button:hover,
nav.navbar .user-nav-center button:hover {
  background-color: #ffffff;
  color: #000000;
}

/* user view */

nav.navbar .user-nav-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding: 10px 0; */
  /* width: 15%; */
  max-width: 120px;
  padding-inline-start: 20px;
}

nav.navbar .user-nav-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-inline-end: 20px;
  /* width: 20%; */
  max-width: 120px;
}
nav.navbar .user-nav-left img,
nav.navbar .user-nav-right img {
  width: 100px;
}

nav.navbar .user-nav-center {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 70%;
  /* justify-content: flex-end;
  align-items: center;
  margin-inline-start: 16vw;
  gap: 20px;
  width: 50%; */
}
nav.navbar .user-nav-center .sign_out,
nav.navbar .user-nav-center .contact_us {
  display: flex;
  justify-content: center;
  background-color: #860704;
  border-radius: 55px;
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #ffffff;
  /* min-width: 100px; */
  height: 47px;
  font-size: 0.8em;
}
nav.navbar .user-nav-center button {
  background-color: #860704;
  border-radius: 55px;
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #ffffff;
  min-width: 140px;
  height: 47px;
}
nav.navbar .user-nav-center button.active {
  color: #000000;
  background-color: #ffffff;
}
nav.navbar .user-nav-center .search-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 55px;
  width: 35px;
  height: 35px;
  padding-right: 0;
}

nav.navbar .user-nav-center .search-icon.open {
  background-color: #460201;
}
@media (max-width: 1100px) {
  nav.navbar .user-nav-left img,
  nav.navbar .user-nav-right img {
    width: 70px;
  }
  nav.navbar .user-nav-center {
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 80%;
  }
  nav.navbar .user-nav-center .sign_out,
  nav.navbar .user-nav-center .contact_us,
  nav.navbar .user-nav-center button {
    height: 40px;
    padding: 5px 10px;
    font-size: 0.7em;
    gap: 5px;
  }
  nav.navbar .user-nav-center button span {
    font-size: 0.7em;
  }
  nav.navbar .user-nav-center button svg {
    width: 15px;
    height: 15px;
  }
  /* super admin style */

  nav.navbar .nav-center {
    gap: 10px;
    justify-content: center;
    width: 60%;
  }

  nav.navbar .nav-center .sign_out span,
  nav.navbar .nav-center button span {
    font-size: 15px;
  }
}

@media (max-width: 950px) {
  /*  user styles  */
  nav.navbar .user-nav-left,
  nav.navbar .user-nav-right {
    display: none;
  }
  nav.navbar.user {
    justify-content: center;
  }
}
