.user-mobile-wrapper .video-intro-image {
  background: linear-gradient(1.22deg, #cb2a27 0.9%, rgba(0, 0, 0, 0) 65.64%),
    url("../../img//users/mobile-background111.jpg");
  background-size: cover;
  width: 100%;
  min-height: 78.5vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.user-mobile-wrapper .video-intro-image .back {
  padding-top: 50px;
}
.user-mobile-wrapper .video-intro-image .title {
  color: #ffffff;
  font-size: 30px;
  text-align: center;
  padding: 0 20%;
  width: 100%;
}

.user-mobile-wrapper .video-intro-main {
  cursor: pointer;
  background-color: rgba(203, 42, 39, 1);
  color: #ffffff;
}
.user-mobile-wrapper .video-intro-main .explanation {
  font-size: 15px;
  text-align: center;
  padding: 0 13%;
}
.user-mobile-wrapper .video-intro-main .section {
  display: flex;
  justify-content: space-between;
  margin: 20px auto;
  border-bottom: 1px solid #ffffff;
  width: 80%;
}
.user-mobile-wrapper .video-intro-main .footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
}
.user-mobile-wrapper .video-intro-main .footer .start-btn {
  background-color: #000000;
  border-radius: 55px;
  padding: 8px 18px;
  display: flex;
  font-weight: 500;
  font-size: 1.2rem;
  gap: 30px;
}
.user-mobile-wrapper .video-intro-main .attached-files {
  margin-bottom: 20px;
  text-align: center;
  text-decoration: underline;
}
