/* Mobile */
.lesson-finished-wrapper {
  background: url("../../img/users/section-end-background.png") no-repeat center
    center;
  background-size: cover;
  /* background-position: initial; */
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.lesson-finished-wrapper .back {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px;
  color: #ffffff;
  width: 100%;
}

.lesson-finished-wrapper .lesson-finished-content {
  padding: 80% 20px 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.lesson-finished-wrapper .lesson-finished-content p {
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
}

.lesson-finished-wrapper .lesson-finished-content .lesson-finished-btn {
  display: flex;
  justify-content: center;
  width: 80%;
  margin-top: 30px;
}
.lesson-finished-wrapper .lesson-finished-content .lesson-finished-btn button {
  background-color: #cb2a27;
  border-radius: 55px;
  color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 8px 0;
}
@media (max-width: 410px) {
  .lesson-finished-wrapper .lesson-finished-content p {
    font-size: 1rem;
  }
}
/* desktop from here */
/* Mobile */
.desktop-lesson-finished-wrapper {
  background: url("../../img/users/desktop/lesson-ended.png") no-repeat center;
  background-size: inherit;
  /* background-position: initial; */
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.desktop-lesson-finished-wrapper .box {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  border: 1px solid #cb2a27;
  border-radius: 12px;
  width: 90%;
  max-width: 1200px;
  height: 60vh;
  margin-top: 5%;
  padding: 50px 100px 50px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin-bottom: 30px;
}
.desktop-lesson-finished-wrapper .box .desktop-lesson-finished-content {
  font-size: 1.5rem;
}

.desktop-lesson-finished-wrapper .box .desktop-lesson-finished-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20%;
  /* padding-top: 10%; */
}
.desktop-lesson-finished-wrapper
  .box
  .desktop-lesson-finished-btn
  .finish-lesson {
  background-color: #cb2a27;
  border-radius: 55px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  padding: 8px 60px;
  cursor: pointer;
}
.desktop-lesson-finished-wrapper .box .desktop-lesson-finished-btn .back {
}
@media (min-width: 1200px) {
  .desktop-lesson-finished-wrapper .box .desktop-lesson-finished-content {
    font-size: 2rem;
  }
}
