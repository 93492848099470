/* Mobile */
.feedback-wrapper {
  background: url("../../img/users/feedback-background.png") no-repeat center
    center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.feedback-wrapper .back {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
  color: #ffffff;
  width: 100%;
  padding: 20px 30px;
}
.feedback-wrapper .main {
  margin-top: 200px;
  background-color: #000000;
  border-radius: 12px;
  width: 90%;
  height: 85vh;
  max-width: 450px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  color: #ffffff;
  margin-bottom: 30px;
}

.feedback-wrapper .main .title {
  font-size: 1.1rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 20px;
}

.feedback-wrapper .main .user-feedback {
  display: flex;
  justify-content: center;
  width: 100%;
}

.feedback-wrapper .main .user-feedback textarea {
  width: 100%;
  height: 40vh;
  border-radius: 12px 12px 0px 0px;
  background: #575757;
  border-bottom: 1px solid #fff;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 1rem;
  color: #fff;
}
.feedback-wrapper .main .error {
  margin-bottom: 30px;
  font-size: 1.1rem;
}
.feedback-wrapper .main .user-feedback textarea::placeholder {
  font-size: 1rem;
  color: #fff;
}

.feedback-wrapper .main .submit-feedback {
  width: 80%;
  margin-bottom: 30px;
}

.feedback-wrapper .main .submit-feedback button {
  background-color: #cb2a27;
  border-radius: 55px;
  color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

@media (max-width: 420px) {
  .feedback-wrapper .main .title {
    font-size: 0.7rem;
  }

  .feedback-wrapper .main {
    height: 63vh;
  }

  .feedback-wrapper .main .user-feedback textarea {
    height: 25vh;
  }
  .feedback-wrapper .main .user-feedback textarea::placeholder {
    font-size: 0.8rem;
  }

  .feedback-wrapper .main .submit-feedback button {
    padding: 8px 0;
  }
}

/* desktop from here */
.desktop-feedback-wrapper {
  background: url("../../img/users/desktop/desktop2.jpg") no-repeat center
    center;
  background-size: cover;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.desktop-feedback-wrapper .box {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  border: 1px solid #cb2a27;
  border-radius: 12px;
  width: 90%;
  max-width: 1200px;
  height: 70vh;
  margin-top: 5%;
  padding: 50px 100px 50px 100px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-start;
  color: #ffffff;
  margin-bottom: 30px;
}
.desktop-feedback-wrapper .box .title {
  font-size: 1.5rem;
  margin-bottom: 20px;
  width: 70%;
}
.desktop-feedback-wrapper .box .feedback {
  width: 80%;
}
.desktop-feedback-wrapper .box .feedback textarea {
  width: 90%;
  height: 30vh;
  border-radius: 12px 12px 0px 0px;
  background: #575757;
  border-bottom: 1px solid #fff;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 1rem;
  color: #fff;
}

.desktop-feedback-wrapper .box .feedback textarea::placeholder {
  font-size: 1rem;
  color: #fff;
}

.desktop-feedback-wrapper .box .bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
  cursor: pointer;
}
.desktop-feedback-wrapper .box .bottom .error {
  font-size: 1.1rem;
}

.desktop-feedback-wrapper .box .bottom .submit-feedback button {
  background-color: #cb2a27;
  border-radius: 55px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  padding: 8px 60px;
}
