.login-container {
  width: 100%;
  min-height: 100vh;
  background: url("../../img/main.jpg") no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
}

.login-logos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 7% 10px 7%;
}
.login-logos .left-logo {
  width: 12%;
}
.login-logos .right-logo {
  width: 15%;
}

.login-headline {
  margin: 20px auto 50px auto;
  color: #ffffff;
  font-size: 2rem;
  font-weight: 600;
}
.login-boxes {
  display: flex;
  justify-content: center;
  gap: 2%;
}

.login-boxes .login,
.login-boxes .register {
  text-align: center;
  padding: 1% 5% 3% 5%;
  /* height: 403px; */
}

.login-boxes .login {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(1, 1, 1, 0.29);
  border: 1px solid #cb2a27;
  box-shadow: 0px 0px 13px #cb2a27;
  border-radius: 12px;
}

.login-boxes form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.login-boxes form .forgot-password {
  color: #cb2a27;
  text-decoration: underline;
  cursor: pointer;
  font-size: 1rem;
}
.login-boxes form .forgot-password:hover {
  font-size: 1.2rem;
  transition: font-size 0.5s;
}
.login-boxes form div input,
select.desktop {
  font-size: 1rem;
  padding-inline-start: 20px;
  border-radius: 12px 12px 0 0;
  height: 47px;
  min-width: 230px;
}
.login-boxes .register form div label {
  position: relative;
}
.register-desktop .form-wrapper.checkbox {
  max-width: 230px;
  width: 100%;
}

.register-desktop .form-wrapper.form-wrapper.checkbox label {
  display: flex;
  /* justify-content: flex-start; */
  flex-direction: column;
  align-items: center;
  max-width: 230px;
  font-size: 0.8em;
}
.register-desktop .form-wrapper.form-wrapper.checkbox label span {
  text-decoration: underline;
  color: #2462d0;
  cursor: pointer;
  font-weight: 400;
}
.register-desktop .form-wrapper.form-wrapper.checkbox label span:hover {
  font-size: 1.1em;
  font-weight: 600;

  transition: 0.5s;
}
/* .register-desktop .form-wrapper.form-wrapper.checkbox input {
  padding-inline-start: 0;
  min-width: 50px;
} */

.login-boxes .register form div label input[type="checkbox"] {
  width: 5px;
  height: 15px;
  /* position: absolute;
  right: 10px;
  top: 5px;
  bottom: 1px; */
}
.login form div input {
  background-color: #414141;
  border-bottom: 2px solid #ffffff;
  color: #ffffff;
}
.login form div input::placeholder {
  color: #ffffff;
}
.login-boxes .register form div input,
select,
.mobile-tabs .tab-content div form div input.mobile {
  background-color: #f2f2f2;
  border-bottom: 2px solid #000000;
  /* min-height: 45px; */
}

.login-boxes .register {
  background-color: #ffffff;
  border-radius: 12px;
}
.login-boxes .register h3,
.login-boxes .login h3 {
  font-size: 1.5rem;
  font-family: "Assistant", sans-serif;
  color: #ffffff;
  margin: 10px;
  font-weight: 500;
}
.login-boxes .register h3 {
  color: #000000;
}
.error {
  color: #cb2a27;
  display: block;
  margin-top: 10px;
  font-size: 15px;
  max-width: 250px;
}
.register form div input[type="button"],
.login form div input[type="button"],
.mobile-tabs .tab-content div form div input[type="button"] {
  background-color: #cb2a27;
  color: #ffffff;
  border-radius: 55px;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
/* all inputs except checkbox  */
.form-wrapper input :not([type="checkbox"]) {
  /* margin-top: 10px; */
  min-height: 45px;
}

.login-boxes .login form div input.login-desktop[type="button"] {
  margin-top: 175px;
}
.login-boxes .login form div input.register-desktop[type="button"] {
  margin-top: 0;
}
.login-container .credit {
  margin: 80px auto 0 auto;
  color: #ffffff;
  font-size: 0.7rem;
}

/* mobile view */
.mobile-tabs {
  padding: 0 50px;
  margin-top: 30px;
}

.mobile-tabs ul.nav.nav-tabs {
  border: none;
  justify-content: space-evenly;
  margin: 0 !important;
}
.mobile-tabs ul.nav.nav-tabs li.nav-item {
  width: 50%;
}
.mobile-tabs ul.nav.nav-tabs li.nav-item button.nav-link {
  background: transparent;
  color: #ffffff;
  width: 100%;
  border: none;
  font-size: 1.3rem;
  font-weight: 600;
}
.mobile-tabs ul.nav.nav-tabs li.nav-item button.nav-link.active {
  color: #000000;
  background: white;
  border: none;
  width: 100%;
  padding: 20px 0 10px 0;
}

.mobile-tabs .tab-content {
  background-color: #ffffff;
  min-height: 50vh;
  border-radius: 0 0 12px 12px;
  padding: 10px 0 50px 0;
}

.mobile-tabs .tab-content div form {
  margin-top: 20px;
}

.mobile-tabs .tab-content div form.login-mobile {
  margin-top: 80px;
}
.mobile-tabs .tab-content div form div {
  padding: 20px 0 0 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.mobile-tabs .tab-content div form div input.mobile,
.mobile-tabs .tab-content div form div select.mobile {
  font-size: 1rem;
  padding-inline-start: 20px;
  border-radius: 12px 12px 0 0;
  height: 47px;
  width: 75%;
}

.mobile-tabs .tab-content div form div input.mobile[type="checkbox"] {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 60px;
  top: 25px;
}
.mobile-tabs .tab-content div form div.mobile-btn {
  padding-bottom: 50px;
}

.mobile-tabs .tab-content div form div input[type="button"] {
  min-width: 60%;
  height: 57px;
}
.mobile-tabs .tab-content div form div input.login-mobile[type="button"] {
  margin-top: 40px;
}

.mobile-tabs .tab-content .forgot-password {
  text-align: center;
  margin-top: 15px;
  cursor: pointer;
}

.mobile-tabs .tab-content .forgot-password button {
  color: #cb2a27;
  background-color: #ffffff;
  text-decoration: underline;
}

@media (max-width: 600px) {
  .login-logos .left-logo {
    width: 20%;
  }
  .login-logos .right-logo {
    width: 25%;
  }
  .login-headline {
    margin: 15px auto 20px auto;
    font-size: 2rem;
  }

  .mobile-tabs .tab-content div form.register-mobile div,
  .mobile-tabs .tab-content div form.login-mobile div {
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .mobile-tabs .tab-content div form.register-mobile div label {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
    font-size: 0.9em;
    text-align: center;
  }
  .mobile-tabs .tab-content div form.register-mobile div label span {
    text-decoration: underline;
    color: #2462d0;
    cursor: pointer;
    font-weight: 400;
  }
  .mobile-tabs .tab-content div form.register-mobile div label span:hover {
    font-size: 1.1em;
    font-weight: 600;
    transition: 0.5s;
  }
}
@media (max-width: 320px) {
  .login-headline {
    font-size: 1.3em;
  }
  .mobile-tabs .tab-content div form div label {
    font-size: 0.8em;
  }
  .mobile-tabs .tab-content div form.register-mobile div label {
    font-size: 0.7em;
  }
  /* .mobile-tabs .tab-content div form div input.mobile[type="checkbox"] {
    left: 40px;
    top: 25px;
  } */
}
