.user-footer {
  position: absolute;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  color: #ffffff;
  background-color: red;
}
