/* all section DESKTOP */
.user-desktop {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  /* padding: 0 20px; */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  background: url("../../img/users//desktop/desktop1-new.jpg");
  /* background-repeat: repeat-y;
  background-size: cover; */
}
.user-desktop.dark,
.desktop-files-wrapper.dark,
.user-community-wrapper.dark {
  filter: brightness(30%);
  background-color: rgba(46, 40, 43, 0.8);
  pointer-events: none;
}
/* 
::-webkit-scrollbar addresses the background of the bar itself. It is usually covered by the other elements
::-webkit-scrollbar-button addresses the directional buttons on the scrollbar
::-webkit-scrollbar-track addresses the empty space “below” the progress bar
::-webkit-scrollbar-track-piece is the top-most layer of the the progress bar not covered by the draggable scrolling element (thumb)
::-webkit-scrollbar-thumb addresses the draggable scrolling element that resizes depending on the size of the scrollable element
::-webkit-scrollbar-corner addresses the (usually) bottom corner of the scrollable element, where two scrollbars might meet
::-webkit-resizer addresses the draggable resizing handle that appears above the scrollbar-corner at the bottom corner of some elements */

.user-desktop::-webkit-scrollbar,
.desktop-files-wrapper::-webkit-scrollbar,
.user-community-wrapper::-webkit-scrollbar {
  width: 5px;
  /* position: absolute; */
  /* right: 100px; */

  /* margin-right: 20px; */
}
.user-desktop::-webkit-scrollbar-track,
.desktop-files-wrapper::-webkit-scrollbar-track,
.user-community-wrapper::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  /* position: absolute;
  left: 100px; */
}

/* .user-desktop-wrapper .user-desktop::-webkit-scrollbar-track-piece {
  background-color: rgba(255, 255, 255, 0.4);
} */
.user-desktop::-webkit-scrollbar-thumb,
.desktop-files-wrapper::-webkit-scrollbar-thumb,
.user-community-wrapper::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 10px;
}

.user-desktop .top {
  display: flex;
}
.user-desktop .welcome-desktop {
  width: 33%;
  color: #ffffff;
  padding: 2% 50px 0 50px;
  font-size: 18px;
}

.user-desktop .welcome-desktop .main-title {
  font-size: 1.3rem;
}
.user-desktop .welcome-desktop .subtitle {
  /* width: 80%; */
  font-size: 17px;
}
.user-desktop .welcome-desktop .bottom {
  font-size: 14px;
}

.user-desktop .separator {
  position: relative;
  top: 20px;
  width: 1px;
  height: 200px;
  background-color: #ffffff;
}

.user-desktop .progress-bar-desktop {
  background-image: url("../../img/users/desktop/Mask.png");
  background-repeat: no-repeat;
  background-position-x: 30vw;
  background-position-y: -10vh;
  background-size: auto;
  position: relative;
  width: 70%;
  /* display: flex;
  flex-direction: column; */
  padding: 3% 50px 50px 50px;
}
.user-desktop .progress-bar-desktop .next {
  color: #ffffff;
  background-color: #cb2a27;
  border-radius: 55px;
  width: 200px;
  padding: 8px 20px;
  border: 1px solid #ffffff;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}
.user-desktop .progress-bar-desktop .percent {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  font-size: 1.2rem;
  color: #ffffff;
}
.user-desktop .user-desktop-bottom {
  padding: 10px 20px;
}
.user-desktop .user-desktop-section {
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #860704;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  /* overflow-x: scroll; */
}
.user-desktop .user-desktop-section .section-title {
  width: 50%;
  display: flex;
  padding: 20px;
  justify-content: space-between;
  color: #ffffff;
  font-weight: 600;
  font-size: 1rem;
}

.user-desktop .user-desktop-section .section-title .sub {
  display: block;
  font-weight: 300;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.user-desktop .user-desktop-bottom .video-wrapper {
  display: flex;
  /* align-items: center; */
  align-items: flex-start; /* Align items at the top */
  gap: 20px;
  overflow-x: auto;
  padding: 10px 20px;
  direction: ltr;
  height: 60vh;
  overflow-y: hidden;
}

.user-desktop .user-desktop-bottom .video-wrapper::-webkit-scrollbar {
  /* width: 3px;
  height: 3px; */
  height: 5px;
  background-color: rgba(255, 255, 255, 0.4);
}

.user-desktop .user-desktop-bottom .video-wrapper::-webkit-scrollbar-track {
  /* width: 3px;
  height: 3px; */
  border-radius: 10px;

  background-color: rgba(255, 255, 255, 0.4);
}

.user-desktop .user-desktop-bottom .video-wrapper::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 10px;
}

.user-desktop .user-desktop-bottom .video-wrapper .video-card {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
  max-width: 270px;
  height: 430px;
  cursor: pointer;
  color: #ffffff;
}
.video-card .video-title {
  /* text-align: left; */
  /* width: 100%;
  height: 100px; */
  flex: 0 0 auto;
  height: 55px;
  padding: 10px 0;
  font-size: 20px;
  font-weight: 500;
  direction: ltr;
}
.video-card .video-message {
  font-size: 0.9rem;
  /* height: 55px;   */
  flex: 1;
}

.video-card .lesson-completed {
  width: 50%;
  font-size: 0.8rem;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  border-radius: 0 12px 12px 0;
  background-color: green;
  padding: 5px 0;
  margin: 5px 0;
}
.video-card .lesson-completed span {
  padding-inline-start: 5px;
}
.video-card .video-divider {
  height: 1px;
  width: 100%;
  background-color: #ffffff;
  margin: 15px 0;
}
.video-card .video-footer {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  width: 100%;
  padding-bottom: 20px;
  cursor: pointer;
}

.video-card .video-footer .download-attached-files {
  text-decoration: underline;
  text-underline-offset: 5px;
}
/* files and downloads DESKTOP */

.desktop-files-wrapper {
  background-image: url("../../img/users/desktop/desktop_files.jpg");
  background-size: auto;
  background-position: right top;
  min-height: 100vh;
  background-repeat: no-repeat;
  overflow-y: scroll;
  height: 80vh;
  padding-bottom: 100px;
}
.desktop-files-wrapper::-webkit-scrollbar {
  background-color: transparent;
}

/* bg-desktop-section-progressbar */

.desktop-files-wrapper {
  background-image: url("../../img/users/desktop/desktop_files.jpg");
  background-size: auto;
  background-position: right top;
  min-height: 100vh;
  background-repeat: no-repeat;
  overflow-y: scroll;
  height: 80vh;
  padding-bottom: 100px;
}

/* community DESKTOP */
.user-community-wrapper {
  background-image: url("../../img/users/desktop/desktop2.jpg");
  background-size: cover;
  background-position: right top;
  min-height: 100vh;
  background-repeat: no-repeat;
  overflow-y: scroll;
  height: 80vh;
  padding: 0 50px 100px 50px;
}

/* MOBILE */
.user-mobile-wrapper {
  position: relative;
}

.user-mobile-wrapper .welcome {
  position: relative;
  height: 50vh;
  top: -10px;
  background-color: #cb2a27;
  padding-block-start: 50px;
  padding-inline-start: 10%;
  color: #ffffff;
  font-size: 1.7rem;
  font-weight: 400;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
  border-radius: 0 0 12px 12px;
  z-index: 20;
}
.welcome h2,
.welcome p {
  padding-top: 10px;
}
.user-mobile-wrapper .welcome h2 {
  font-size: 3rem;
}
.user-mobile-wrapper .welcome .long-title {
  width: 80%;
}

.user-mobile-wrapper .progress-bar {
  height: 20vh;
  background-color: #860704;
  position: relative;
  bottom: 20px;
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

/* progress bar mobile*/
.user-mobile-wrapper .progress-bar .progress {
  background-color: #ffffff;
  margin: 0 auto;
  width: 70vw;
}

.user-mobile-wrapper .progress-bar .progress-title {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
  color: #ffffff;
  font-size: 25px;
}
/* desktop progress bar */

/* .user-desktop-wrapper .progress-bar .progress .bg-desktop-custom-css {
  background-color: #860704;
} */
.progress-bar-desktop .progress .progress-bar.bg-desktop-custom-css,
.user-desktop-bottom
  .user-desktop-section
  .progress
  .progress-bar.bg-desktop-custom-css {
  background-color: #cb2a27;
}
/* mobile progress bar  */
.user-mobile-wrapper .progress-bar .progress .bg-custom-css {
  background-color: #000000;
}

.user-mobile-wrapper .next-video {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
.user-mobile-wrapper .next-video button {
  background-color: red;
  padding: 13px 20px;
  border-radius: 55px;
  color: #ffffff;
  width: 80%;
  max-width: 280px;
}

@media (min-width: 390px) {
  .user-mobile-wrapper .welcome .long-title {
    width: 70%;
  }
}

@media (min-width: 490px) {
  .user-mobile-wrapper .welcome {
    font-size: 1.9rem;
  }
  .user-mobile-wrapper .welcome .long-title {
    width: 60%;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1150px) {
  .user-desktop .user-desktop-section .section-title {
    width: 50%;
  }
}
@media (min-width: 1151px) {
  .user-desktop .welcome-desktop .main-title {
    font-size: 35px;
  }
  .user-desktop .welcome-desktop .subtitle {
    font-size: 25px;
  }
  .user-desktop .welcome-desktop .bottom {
    font-size: 18px;
  }
}
