/* mobile  */

.rate-lesson-wrapper {
  background: url("../../img/users//rate-lesson-background-full.png") no-repeat
    center center;
  background-size: cover;
  min-height: 90vh;
}

.rate-lesson-wrapper .top {
  display: flex;
  color: #ffffff;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 30px;
}
.rate-lesson-wrapper .main {
  width: 90%;
  height: 50vh;
  max-width: 450px;
  margin: 65% auto 50px auto;
  background-color: #000000;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
  color: #ffffff;
}
.rate-lesson-wrapper .main .title {
  margin-bottom: 20px;
  font-size: 1.3rem;
}

.rate-lesson-wrapper .main .rating .hamburgers .mobile-hamburger {
  display: flex;
  gap: 10px;
  cursor: pointer;
}

.rate-lesson-wrapper .main .rating-in-numbers {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  color: #727272;
}
.rate-lesson-wrapper .main .submit {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  width: 80%;
}

.rate-lesson-wrapper .main .submit button {
  background-color: #cb2a27;
  border-radius: 55px;
  color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 8px 0;
}

@media (min-width: 450px) {
  .rate-lesson-wrapper .main .title {
    font-size: 1.5rem;
  }

  .rate-lesson-wrapper .main {
    height: 60vh;
  }

  .rate-lesson-wrapper .main .submit button {
    padding: 12px 0;
  }
}

/* desktop from here */
.rate-lesson-desktop-wrapper {
  background: url("../../img/users/desktop/rateing.png") no-repeat center center;
  background-size: cover;
  min-height: 90vh;
}

.rate-lesson-desktop-wrapper .rate-desktop-box {
  width: 80%;
  height: 70vh;
  margin: 5% auto 50px auto;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  border: 1px solid #cb2a27;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 100px;
  color: #ffffff;
}
.rate-lesson-desktop-wrapper .rate-desktop-box .title {
  font-size: 2rem;
  margin-bottom: 20px;
}

.rate-lesson-desktop-wrapper
  .rate-desktop-box
  .rating
  .hamburgers
  .desktop-hamburger {
  display: flex;
  cursor: pointer;

  gap: 17px;
}
.rate-lesson-desktop-wrapper .rate-desktop-box .rating-in-numbers {
  margin-top: 25px;
  padding-left: 165px;
}
.rate-lesson-desktop-wrapper .rate-desktop-box .bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 5%;
  width: 100%;
}

.rate-lesson-desktop-wrapper .rate-desktop-box .bottom .error {
  font-size: 1.3rem;
}

.rate-lesson-desktop-wrapper .rate-desktop-box .bottom .back {
  font-size: 20px;
}

.rate-lesson-desktop-wrapper .rate-desktop-box .bottom .back,
.rate-lesson-desktop-wrapper .rate-desktop-box .bottom .submit {
  cursor: pointer;
}
.rate-lesson-desktop-wrapper .rate-desktop-box .bottom .submit button {
  background-color: #cb2a27;
  color: #ffffff;
  border-radius: 55px;
  padding: 8px 40px;
  font-size: 20px;
}
