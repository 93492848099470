.forgot-pass-title,
.reset-password-title {
  margin: 80px auto 0 auto;
  color: #ffffff;
}
.forgot-password-container,
.reset-password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

form.forgot-password-form,
form.reset-password-form {
  margin-top: 50px;
  width: clamp(250px, 40%, 450px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
form.forgot-password-form .form-control,
form.reset-password-form .form-control {
  /* margin-bottom: 25px; */
  background-color: transparent;
  border: none;
}

form.forgot-password-form .form-control input,
form.reset-password-form .form-control input {
  background-color: #575757;
  border-radius: 12px 12px 0px 0px;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #ffffff;
  padding-left: 20px;
  color: #ffffff;
  border: none;
}

form.forgot-password-form .form-control input::placeholder,
form.reset-password-form .form-control input::placeholder {
  color: #ffffff;
}

form.forgot-password-form .form-control .back-to-login {
  color: #cb2a27;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  font-size: 1rem;
}

form.forgot-password-form .form-control .back-to-login:hover {
  font-size: 1.2rem;
  transition: font-size 0.5s;
}
form.forgot-password-form .error,
form.reset-password-form .error {
  color: #cb2a27;
  margin: 20px;
  font-size: 22px;
}

form.forgot-password-form .form-submission button,
form.reset-password-form .form-submission button {
  background-color: #cb2a27;
  color: #ffffff;
  padding: 10px 50px;
  font-size: 1.2rem;
  border-radius: 55px;
}

/* style for reset-password only */

form.reset-password-form .form-control input:focus {
  outline: none;
}

.reset-password-container form.reset-password-form .form-control label {
  display: flex;
  background-color: #575757;
  border-radius: 12px 12px 0px 0px;
  border-bottom: 1px solid #ffffff;
  padding-right: 30px;
}
.reset-password-container form.reset-password-form .form-control label .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
