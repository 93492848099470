.search-results-wrapper {
  background-image: url("../../img/users/desktop/desktop2.jpg");
  background-size: cover;
  background-position: right top;
  min-height: 100vh;
  background-repeat: no-repeat;
  min-height: 80vh;
  padding: 0 50px 100px 50px;
}
.search-results-wrapper .title {
  color: #ffffff;
  font-size: 1.8rem;

  padding: 20px 0;
}

.search-results-wrapper .results {
  width: 100%;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.results .no-results {
  /* text-align: center; */
  font-size: 1.3rem;
}
/* video search results */
.results .video-results {
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #860704;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  height: 80vh;
  overflow-x: auto;
}
.results .video-results h3,
.results .community-results h3,
.results .files-results h3 {
  color: #ffffff;
  padding: 20px;
}
.results .video-results .video-wrapper {
  display: flex;
  /* align-items: center; */
  align-items: flex-start;
  gap: 20px;
  overflow-x: auto;
  padding: 10px 20px;
  direction: ltr;
  /* height: 60vh; */
  color: #ffffff;
  cursor: pointer;
}
.results .video-results .video-wrapper::-webkit-scrollbar,
.results .community-results::-webkit-scrollbar,
.results .files-results::-webkit-scrollbar {
  /* background-color: rgba(255, 255, 255, 0.4); */
  width: 0px;
}
.results .video-results .video-wrapper::-webkit-scrollbar-track,
.results .community-results::-webkit-scrollbar-track,
.results .files-results::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.4);
}
.results .video-results .video-wrapper::-webkit-scrollbar-thumb,
.results .community-results::-webkit-scrollbar-thumb,
.results .files-results::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 10px;
}
.results .video-results .video-wrapper .video-card {
  width: 35%;
}

/* community search results */

.results .community-results {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  border: 1px solid #cb2a27;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.results .files-results {
  background-color: rgba(255, 255, 255, 0.4);
  border: 1px solid #cb2a27;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  padding: 20px;
}

.results .files-results .files-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
