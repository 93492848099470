/* desktop same as mobile different top and left */
.notification-popup-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 550px;
  transform: translate(-50%, -50%);
  z-index: 5000;
  /* transform: translate(-50%, -50%); */
}

.notification-popup-mobile,
.notification-popup-desktop {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* position: fixed;
  top: 20vh;
  left: 10vw; */
  background-color: #ffffff;
  max-width: 550px;
  min-height: 180px;
  max-height: 60vh;
  overflow-y: scroll;
  border-radius: 12px;
  width: 80vw;
  padding: 30px 20px;
  z-index: 6000;
  overflow-y: hidden;
}

.notification-popup-desktop {
  position: static;
}
/* .notification-popup-mobile.long-message {
  top: 5vh;
} */
.notification-popup-mobile .title,
.notification-popup-desktop .title {
  font-size: 1rem;
}
.notification-popup-mobile .message,
.notification-popup-desktop .message {
  color: #000000;
}

/* bell image is outside the popup because the popup div has overflowY:scroll and that cuts the image in the top of the container*/
.popup-bell-img-mobile {
  position: fixed;
  top: -7%;
  right: -4%;
  z-index: 101;
}
.popup-bell-img-desktop {
  position: absolute;
  top: -15px;
  right: -3%;
  z-index: 101;
}

.notification-popup-mobile .footer,
.notification-popup-desktop .footer {
  margin: 20px 0 10px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.notification-popup-mobile .footer .user-details,
.notification-popup-desktop .footer .user-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.notification-popup-mobile .close-btn button,
.notification-popup-desktop .close-btn button {
  cursor: pointer;
  max-width: 150px;
  background-color: #620402;
  padding: 10px 30px;
  border-radius: 55px;
  color: #ffffff;
  font-size: 1rem;
  margin: 10px 0;
}
@media (min-width: 370px) {
  .notification-popup-mobile .close-btn button {
    padding: 10px 40px;
  }
}
