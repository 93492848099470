.player-container:first-child iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.player-container .back-button {
  color: #ffffff;
  display: flex;
  align-items: center;
  position: absolute;
  top: 100px;
  right: 50px;
  cursor: pointer;
}
