.notifications .filter-notifications {
  margin: 20px 0 20px 0;
  background-color: #000000;
  padding: 10px 20px;
  border-radius: 12px;
}

.notifications .filter-notifications .filter-title h1 {
  font-size: 1rem;
  color: #ffffff;
  text-align: left;
  margin-bottom: 10px;
}

.notifications .filter-notifications .filters {
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  gap: 10px;
}
.notifications .filter-notifications .filters div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.notifications .filter-notifications .filters select {
  appearance: none;
  width: clamp(100px, 10vw, 200px);
  font-size: 0.7rem;
  margin: 0 10px; /* Add horizontal spacing between select elements */
  text-align: center; /* Center text horizontally within the selects */
  margin: 10px 0;
  background-color: #3e3b3b;
  color: #ffffff;
  border-radius: 7px 7px 0px 0px;
  border-bottom: 1px solid #fff;
  height: 30px;
}
.notifications .filter-notifications .filters .filter-notification-select {
  /* appearance: none; */
}

.notifications
  .filter-notifications
  .filters
  .filter-notification-select::-ms-expand {
  display: none;
}
.notifications
  .filter-notifications
  .filters
  .filter-notification-select::-ms-expand:hover {
  background-color: #dcdbdb;
}

/* .notifications
  .filter-notifications
  .filters
  select.filter-notification-select
  option:checked {
  background-color: #dcdbdb;
} */

.notifications .data-wrapper {
  padding: 18px 13px 20px 0;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 350px;
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.notifications .data-wrapper::-webkit-scrollbar {
  width: 3px;
}
.notifications .data-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.notifications .data-wrapper::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 10px;
}

.notifications .data-wrapper div img {
  position: absolute;
  width: 45px;
  right: -17px;
  top: -18px;
}
.notifications .data-wrapper div p {
  font-size: 1rem;
  color: #000000;
}

.notifications .data-wrapper button {
  cursor: pointer;
  /* width: 45%; */
  max-width: 150px;
  background-color: #620402;
  padding: 7px 40px;
  border-radius: 55px;
  color: #ffffff;
  font-size: 1rem;
}

.notifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 7px;
}

.notifications h3 {
  color: #ffffff;
  font-size: clamp(0.8125rem, 0.5341rem + 0.6364vw, 1.25rem);
}
.notifications p {
  color: #ffffff;
  font-size: clamp(0.75rem, 0.5511rem + 0.4545vw, 1.0625rem);
  margin-bottom: 0px;
  font-size: 0.8rem;
}
.notifications .notification-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 7px;
  width: 100%;
}
.notifications .notification-header .date,
.notifications .notification-header .created-by {
  font-size: clamp(0.7rem, 0.2386rem + 0.4545vw, 1rem);
}
.notifications .data-wrapper .notification-type {
  margin: 5%;
  font-size: clamp(0.8rem, 0.2386rem + 0.4545vw, 1rem);
}
.notifications .data-wrapper .notification-message {
  margin-bottom: 5%;
  font-size: clamp(0.8rem, 0.2386rem + 0.4545vw, 1rem);
}
.notifications .animal-wrapper {
  margin-top: 50px;
  display: flex;
  gap: 10px;
  justify-content: space-evenly;
  width: 100%;
}
.notifications .animal-wrapper div img {
  width: 60px;
  height: 60px;
}
@media (min-width: 1201px) {
  .notifications .data-wrapper .filter-notifications .filters {
    justify-content: space-evenly;
  }

  .notifications .data-wrapper .filter-notifications .filters select {
    width: 90%;
    font-size: 1rem;
  }
}
@media (max-width: 1200px) {
  .notifications h3 {
    font-size: 1.5rem;
  }
  .notifications p {
    font-size: 0.8rem;
  }
}
@media (min-width: 741) and (max-width: 950px) {
  .notifications .data-wrapper .notification-type {
    font-size: clamp(0.9rem, 0.2386rem + 0.4545vw, 1rem);
  }
  .notifications .data-wrapper .notification-message {
    font-size: clamp(0.9rem, 0.2386rem + 0.4545vw, 1rem);
  }
}
@media (min-width: 501px) and (max-width: 740px) {
  .notifications .data-wrapper .notification-type {
    font-size: clamp(0.9rem, 0.2386rem + 0.4545vw, 1rem);
  }
  .notifications .data-wrapper .notification-message {
    font-size: clamp(0.9rem, 0.2386rem + 0.4545vw, 1rem);
  }

  .notifications .data-wrapper .filter-notifications .filter-title h1 {
    font-size: 1.3rem;
  }

  .notifications .data-wrapper .filter-notifications .filters {
    display: flex;
    justify-content: space-evenly; /* Center items horizontally */
  }
  .notifications .data-wrapper .filter-notifications .filters select {
    width: 100%;
  }
  .notifications .filter-notifications .filters select {
    width: clamp(100px, 50vw, 200px);
  }
}

@media (min-width: 351px) and (max-width: 500px) {
  .notifications .notification-header .date,
  .notifications .notification-header .created-by {
    font-size: 0.7rem;
  }
  .notifications .filter-notifications .filters select {
    width: clamp(100px, 35vw, 200px);
  }
}

@media (max-width: 350px) {
  .notifications .animal-wrapper div img {
    width: 40px;
    height: 40px;
  }
  .notifications .data-wrapper div p {
    font-size: 0.8rem;
  }
  .notifications .notification-header .date,
  .notifications .notification-header .created-by {
    font-size: 0.5rem;
  }
  .notifications .filter-notifications .filters select {
    width: clamp(100px, 30vw, 200px);
  }
}
