/* .user {
  margin-top: 20px;
} */
/* DESKTOP */
.user-desktop-title {
  color: #ffffff;
  font-weight: 400;
  font-size: 30px;
  padding: 20px 0 0 15px;
}
.desktop-downloads-wrapper {
  margin-top: 50px;
  padding: 3% 10% 10% 5%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

.desktop-downloads-wrapper .download {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #ffffff;
  /* width: 350px; */
  /* max-width: 20vw; */
  border-radius: 12px;
  /* margin-bottom: 20px; */
  padding: 20px;
}

/* MOBILE */
.mobile-downloads-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  /* overflow-y: scroll; */
}
.mobile-downloads-wrapper .download {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #ffffff;
  width: 80%;
  max-width: 420px;
  border-radius: 12px;
  margin-bottom: 20px;
  padding: 20px;
}
.download .download-section-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.download .download-section-title span {
  display: block;
}
.download .download-section-title span.download-amount {
  padding-inline-end: 20px;
  color: #858585;
  font-weight: 400;
}
.download .download-title {
  font-weight: 500;
  font-size: 1.2rem;
  margin: 10px 0;
}
.download .download-message {
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  height: 100px;
  margin-bottom: 30px;
}
.download .download-message-open {
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  height: auto;
}
.download .download-btn {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  position: relative;
}
.download .download-btn button {
  background-color: #620402;
  border-radius: 55px;
  color: #ffffff;
  width: 120px;
  padding: 10px 20px;
  font-size: 0.8em;
}

@media (max-width: 450px) {
  .download .download-btn button {
    width: 100px;
    padding: 10px;
  }
}
@media (max-width: 350px) {
  .download .download-btn button {
    width: 80px;
    font-size: 0.7em;
  }
}
