.links_popup_wrapper {
  position: fixed;
  top: 41%;
  left: 50%;
  transform: translate(-50%, -40%);
  background-color: #ffffff;
  padding: 20px;
  width: 80vw;
  max-width: 500px;
  border-radius: 12px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  /* cursor: pointer; */
  z-index: 100;
  overflow-x: hidden;
  cursor: pointer;
  border: 1px solid #e0e0e0;
}
.links_popup_wrapper .header {
  display: flex;
  justify-content: space-between;
}
.links_popup_wrapper .header .title {
  font-size: 1.6rem;
  text-align: center;
  width: 90%;
}
.links_popup_wrapper .body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.links_popup_wrapper .body .link-wrapper {
  width: 100%;
}
.links_popup_wrapper .body .link {
  color: #620402;

  font-size: 1.2em;
}
.links_popup_wrapper .body .link:hover {
  text-decoration: underline;
  background: #620402;
  color: #ffffff;
}
.links_popup_wrapper .close-popup {
  position: relative;
  bottom: -100px;
  background-color: #620402;
  text-align: center;
  width: 25%;
  margin: 0 auto;
  padding: 5px 10px;
  border-radius: 55px;
  color: #ffffff;
  font-size: 1em;
}
