/* MOBILE HERE */
.mobile-quiz .quiz-wrapper {
  color: #ffffff;
  margin-bottom: 20px;
}

.mobile-quiz .quiz-wrapper .top {
  background: url("../../img/users/quiz-image.png") no-repeat center center;
  background-size: cover;
  height: 45vh;
  position: relative;
  top: -10px;
}
.mobile-quiz .quiz-wrapper .dots {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.mobile-quiz .quiz-wrapper .dots .dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 3px solid #ffffff;
}
.mobile-quiz .quiz-wrapper .dots .dot.active {
  background-color: red;
}

.mobile-quiz .quiz-wrapper .question {
  font-size: 1.9rem;
  text-align: center;
  padding: 30px 20px;
  font-weight: 400;
}

.mobile-quiz .quiz-wrapper .boxes {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.mobile-quiz .quiz-wrapper .boxes .box-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.mobile-quiz .quiz-wrapper .boxes .box-wrapper .box {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #000000;
  border-radius: 12px;
  border: 1px solid #e00602;
  padding: 10px;
  box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.35);
}
.mobile-quiz .quiz-wrapper .boxes .box-wrapper .box.chosen {
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.35);
}
.mobile-quiz .quiz-wrapper .boxes .box-wrapper .box.correct {
  background: #22a777;
  border: 1px solid #22a777;
  box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.35);
}
.mobile-quiz .quiz-wrapper .boxes .box-wrapper .box.wrong {
  background-color: #ff0000;
  border: 1px solid #ff0000;
  box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.35);
}

.mobile-quiz .quiz-wrapper .watch-again {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
  cursor: pointer;
}

.mobile-quiz .quiz-wrapper .watch-again p {
  text-decoration: underline;
  text-underline-offset: 4px;
}

.mobile-quiz .quiz-wrapper .next-question {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  cursor: pointer;
}
.mobile-quiz .quiz-wrapper .next-question button {
  background: #f00;
  border-radius: 12px;
  padding: 10px 70px;
  color: #ffffff;
}
.mobile-quiz .quiz-wrapper .error {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 1rem;
}

@media (max-width: 350px) {
  /* .mobile-quiz  .quiz-wrapper .boxes .box-wrapper .box {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  .mobile-quiz .quiz-wrapper .boxes .answer {
    font-size: 0.7rem;
  }

  .mobile-quiz .quiz-wrapper .question {
    font-size: 1rem;
  }
}

/* DESKTOP FROM HERE */
.desktop-quiz {
  background: url("../../img/users/desktop/quiz-desktop.png") right;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  justify-content: center;

  align-items: center;
}

.desktop-quiz .quiz-wrapper {
  border-radius: 12px;
  border: 1px solid #cb2a27;
  background: rgba(0, 0, 0, 0.5);
  width: 80%;
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  padding: 30px 0;
}
.desktop-quiz .quiz-wrapper .quiz-progress-bar {
  margin-top: 20px;
  width: 80%;
}
.desktop-quiz .quiz-wrapper .quiz-progress {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.desktop-quiz .quiz-wrapper .quiz-progress .line {
  background-color: #ffffff;
  height: 8px;
  width: 55%;
  margin-inline-start: 10%;
  border-radius: 12px;
  position: relative;
}
.desktop-quiz .quiz-wrapper .quiz-progress .line > span.red {
  display: inline-block;
  width: 33.3%;
  height: 8px;
  background-color: #cb2a27;
  position: relative;
  top: -9px;
}
.desktop-quiz .quiz-wrapper .quiz-progress .line :first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.desktop-quiz .quiz-wrapper .quiz-progress .line :last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.desktop-quiz .quiz-wrapper .quiz-progress .question-number {
  margin-left: 10px;
}
.desktop-quiz .quiz-wrapper .question {
  font-size: 1.9rem;
  padding: 30px 10%;
  font-weight: 600;
  width: 60%;
  text-align: center;
}
.desktop-quiz .quiz-wrapper .error {
  width: 60%;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 1.5rem;
}
.desktop-quiz .quiz-wrapper .boxes {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.desktop-quiz .quiz-wrapper .boxes .box-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 35px;
}

/*  last answer in list contains different structure in desktop  */
.desktop-quiz .quiz-wrapper .boxes .box-wrapper-last {
  display: flex;
  justify-content: space-evenly;
}
.desktop-quiz .quiz-wrapper .boxes .box-wrapper-last .box-with-buttons {
  display: flex;
  width: 90%;
  flex-direction: row;
  margin-left: 2%;
  justify-content: space-between;
}

/* answer box */
.desktop-quiz .quiz-wrapper .boxes .box-wrapper .box {
  width: 50%;
  cursor: pointer;
  background-color: #000000;
  border-radius: 12px;
  border: 1px solid #e00602;
  padding: 10px;
  box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.35);
  margin-left: 5.5%;
  background-color: #000000;
  text-align: center;
}
/*bottom answer box for desktop different structure*/
.desktop-quiz .quiz-wrapper .boxes .box-wrapper-last .box-with-buttons .box {
  display: inline-block;
  width: 55%;
  cursor: pointer;
  background-color: #000000;
  border-radius: 12px;
  border: 1px solid #e00602;
  box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.35);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
}
.desktop-quiz .quiz-wrapper .boxes .box-wrapper .box.chosen,
.desktop-quiz
  .quiz-wrapper
  .boxes
  .box-wrapper-last
  .box-with-buttons
  .box.chosen {
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.35);
}

.desktop-quiz .quiz-wrapper .boxes .box-wrapper .box.correct,
.desktop-quiz
  .quiz-wrapper
  .boxes
  .box-wrapper-last
  .box-with-buttons
  .box.correct {
  background: #22a777;
  border: 1px solid #22a777;
  box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.35);
}
.desktop-quiz .quiz-wrapper .boxes .box-wrapper .box.wrong,
.desktop-quiz
  .quiz-wrapper
  .boxes
  .box-wrapper-last
  .box-with-buttons
  .box.wrong {
  background-color: #ff0000;
  border: 1px solid #ff0000;
  box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.35);
}

.desktop-quiz
  .quiz-wrapper
  .boxes
  .box-wrapper-last
  .box-with-buttons
  .watch-again {
  display: flex;
  gap: 10px;
  align-items: center;
}

.desktop-quiz
  .quiz-wrapper
  .boxes
  .box-wrapper-last
  .box-with-buttons
  .watch-again
  p {
  margin: 0;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.desktop-quiz
  .quiz-wrapper
  .boxes
  .box-wrapper-last
  .box-with-buttons
  .next-question {
  border-radius: 55px;
  background: var(--cb-2-a-27, #cb2a27);
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.desktop-quiz
  .quiz-wrapper
  .boxes
  .box-wrapper-last
  .box-with-buttons
  .next-question
  button {
  background: var(--cb-2-a-27, #cb2a27);
  width: 20%;
  color: #ffffff;
  display: flex;
}
