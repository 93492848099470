.delete-file-popup-container {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  /* transform: translate(-45%, -40%); */
  /* padding: 20px; */
  /* width: 80vw;
  max-width: 700px;
  border-radius: 12px; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 110;
}
.delete-file-popup-container .delete-file-popup-wrapper {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-45%, -40%);
  background-color: #ffffff;
  padding: 20px;
  width: 70vw;
  max-width: 500px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  z-index: 120;
}
.delete-file-popup-wrapper .header {
  display: flex;
  justify-content: flex-end;
}
.delete-file-popup-wrapper .body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.delete-file-popup-wrapper .body .title {
  font-size: clamp(0.75rem, 0.6234rem + 0.7237vw, 1.4375rem);
  font-weight: 600;
  margin: 20px 0;
}
.delete-file-popup-wrapper .body .file-name {
  font-size: clamp(0.5625rem, 0.4128rem + 0.8553vw, 1.375rem);
  margin-bottom: 20px;
}
.delete-file-popup-wrapper .body .buttons {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
}
.delete-file-popup-wrapper .body .buttons button {
  border-radius: 55px;
  padding: 2% 10%;
  font-size: clamp(0.875rem, 0.8068rem + 0.3896vw, 1.25rem);
  color: #ffffff;
}
.delete-file-popup-wrapper .body .buttons .delete-file {
  background-color: #858585;
}

.delete-file-popup-wrapper .body .buttons .cancel-delete-file {
  background-color: #cb2a27;
}
