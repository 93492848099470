.upload-wrapper {
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  gap: 50px;
}

.create-section {
  width: 30%;
  background-color: #000000;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  overflow-y: scroll;
}

.create-section::-webkit-scrollbar {
  width: 0px;
}

.create-section > div {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.create-section form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.create-section form .form-wrapper {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
  max-width: 320px;
}

.create-section form .form-wrapper .error {
  text-align: center;
}

.create-section form input,
.create-section form textarea {
  height: 45px;
  background-color: #535353;
  border-radius: 12px 12px 0 0;
  width: 100%;
  padding: 5%;
  color: #ffffff;
}

.create-section form textarea {
  min-height: 100px;
}

.create-section form input::placeholder,
.create-section form textarea::placeholder {
  color: #ffffff;
  padding: 3px 10px;
}

.create-section form .btn-wrapper button {
  background-color: #cb2a27;
  padding: 8px 40px;
  border-radius: 55px;
  cursor: pointer;
  color: #ffffff;
}

.sections {
  width: 60%;
  height: 55vh;
  overflow-y: scroll;
}
.sections::-webkit-scrollbar {
  width: 0px;
}
.sections .accordion {
  margin-bottom: 20px;
  border: none;
}
.sections .accordion > div {
  border-radius: 12px;
  border: none;
}

#upload-accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#upload-accordion-header button {
  background-color: #000000;
  color: #ffffff;
  box-shadow: none;
  gap: 10px;
  width: 100%;
  text-align: left;
  padding: 20px 30px;
}

#upload-accordion-header button.collapsed::after,
#upload-accordion-header button::after {
  max-width: 2rem;
  width: 1rem;
  color: #ffffff;
  content: "";
}
#upload-accordion-header button .upload-accordion-title {
  width: 20%;
}

#upload-accordion-header button .upload-accordion-header-edit-section {
  display: flex;
  gap: 10px;
  width: 35%;
}

/* #upload-accordion-header button.collapsed,
#upload-accordion-header button {
  background-color: #000000;
  color: #ffffff;
  box-shadow: none;
  gap: 100px;
  min-width: 100%;
}

#upload-accordion-header button.collapsed::after,
#upload-accordion-header button::after {
  max-width: 5vw;
  color: #ffffff;
} */
.accordion-body {
  margin-bottom: 20px;
}

.accordion-body .lesson-titles,
.accordion-body .lesson-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
  padding: 0 20px;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 15px;
  /* line-height: 3; */
  border-bottom: 2px solid black;
  cursor: pointer;
  font-size: clamp(0.8125rem, 0.6534rem + 0.3636vw, 1.0625rem);
}

.accordion-body .lesson-titles {
  font-size: clamp(0.8125rem, 0.5341rem + 0.6364vw, 1.25rem);
}

.accordion-body .lesson-titles > div,
.accordion-body .lesson-wrapper > div {
  /* Adjust flex-basis to control width */
  flex: 1 0 20%;
}

.accordion-body .no-lesson {
  text-align: center;
  font-weight: 500;
}

.accordion-body .lesson-wrapper .lesson-title,
.accordion-body .lesson-titles .lesson-title {
  /* width: 20%; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.accordion-body .lesson-wrapper .lesson-title,
.accordion-body .lesson-wrapper .lesson-edit,
.accordion-body .lesson-wrapper .add-quiz {
  align-self: baseline;
}
.accordion-body .lesson-wrapper .lesson-edit,
.accordion-body .lesson-wrapper .add-quiz {
  display: flex;
  justify-content: center;
}
.accordion-body .lesson-wrapper .change-order {
  align-self: baseline;
  display: flex;
  gap: 10px;
  align-items: baseline;
}

.accordion-body .lesson-wrapper .add-quiz {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 950px) {
  .accordion-body .lesson-wrapper .lesson-title,
  .accordion-body .lesson-titles .lesson-title,
  .accordion-body .lesson-wrapper .lesson-description,
  .accordion-body .lesson-titles .lesson-Description,
  .accordion-body .lesson-titles .lesson-edit,
  .accordion-body .lesson-titles .lesson-quiz,
  .accordion-body .lesson-titles .lesson-move {
    /* width: 20%; */
    font-size: 0.8rem;
    line-height: 1.2;
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin-bottom: 10px;
  }
}
