.mobile-navbar {
  display: flex;
  justify-content: center;
  gap: 14%;
  align-items: center;
  padding: 30px 0;
}
.mobile-navbar div img {
  width: 18vw;
}

.mobile-navbar div img.logo-center {
  width: 25vw;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: relative;
  width: 26px;
  height: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
}

/* Color/shape of burger icon bars on hover*/
/* .bm-burger-bars-hover {
  background: #a90000;
} */

/* Position and sizing of clickable cross button */
/* .bm-menu-wrap.admin {
  height: 12px;
  width: 12px;
} */

/* Color/shape of close button cross */
/* .bm-cross {
  background: #ffffff;
} */

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap.admin {
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
}

/* General sidebar styles */
.bm-menu-wrap.admin .bm-menu {
  cursor: pointer;
  background: rgba(134, 7, 4, 1);
  font-family: 0.5rem;
  padding-top: 100px;
}

/* Morph shape necessary with bubble or elastic */
/* .bm-menu-wrap.admin .bm-morph-shape {
  fill: #373a47;
} */

/* Wrapper for item list */
.bm-menu-wrap.admin .bm-item-list {
  color: #000000;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Individual item */
.bm-menu-wrap.admin .bm-item {
  display: flex !important;
  color: #ffffff;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  height: 100px;
  width: 150px;
  margin: 0 auto;
}

.bm-menu-wrap.admin .bm-item:not(:last-child) {
  border-bottom: 1px solid #ffffff;
}
.bm-menu-wrap.admin .menu-item {
  background: rgba(98, 4, 2, 1);
  border-radius: 12px;
  /* padding: 0.5rem; */
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Styling of overlay */
/* .bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
.my-overlay {
  background: rgba(0, 0, 0, 0.3);
} */
