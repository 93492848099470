@media (max-width: 380px) {
  /* .admin-mobile-wrapper .mobile-statistics-wrapper {
    margin-top: 100px;
  } */

  .back-btn.super-admin .left-title.super-admin,
  .back-btn.admin .left-title.admin,
  .back-btn.user .left-title.user {
    font-size: 1.5rem;
  }
}

.back-btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  color: #ffffff;
  width: 100%;
  padding: 20px 20px 0 20px;
}

.back-btn .left-title {
  font-weight: 400;
}

.back-btn {
  font-size: 1.5rem;
}

/* .back-btn .left-title.big {
  font-size: 2rem;
}
.back-btn .left-title.small {
  font-size: 1.4rem;
} */
.back-btn .right-section {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.3rem;
}

/* for community - MOBILE */
/* .admin-home-page-mobile-community .back-btn {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #ffffff;
  margin-bottom: 30px;
} */
