/* MOBILE FOOTER */
.no-footer {
  display: none;
}

.super-admin-footer {
  position: relative;
  background-color: #cb2a27;
  bottom: 0px;
  width: 100%;
}
.super-admin-footer-more-space {
  position: relative;
  background-color: #cb2a27;
  bottom: -63px;
  width: 100%;
}
/* .mobile-footer {
  position: relative;
  background-color: #cb2a27;
  bottom: 0;
  width: 100%;
} */

.dark-mode {
  filter: brightness(30%);
  background-color: rgba(46, 40, 43, 0.8);
  pointer-events: none;
}

.mobile-footer .credit {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #ffffff;
  font-size: 0.7rem;
  padding: 20px 0;
}
