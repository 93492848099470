.upload-popup-wrapper {
  position: fixed;
  top: 41%;
  left: 50%;
  transform: translate(-50%, -40%);
  background-color: #ffffff;
  padding: 20px;
  width: 80vw;
  max-width: 700px;
  border-radius: 12px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  /* cursor: pointer; */
  z-index: 100;
  overflow-y: scroll;
  overflow-x: hidden;
  cursor: pointer;
}
.upload-popup-wrapper::-webkit-scrollbar {
  background: #7c7979;
  border-radius: 10px;
  width: 10px;
}

.upload-popup-wrapper::-webkit-scrollbar-thumb {
  width: 5px;
}

.upload-popup-wrapper .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* padding for edit section header popup */
.upload-popup-wrapper .header.header-edit-section {
  padding: 10px 20px 10px 146px;
}

/* padding for edit lesson header popup */
.upload-popup-wrapper .header.header-edit-lesson {
  padding: 10px 20px 10px 85px;
}

/* padding for add lesson header popup */
.upload-popup-wrapper .header.header-add-lesson {
  padding: 10px 20px 10px 80px;
}

/* header title */
.upload-popup-wrapper .header .title {
  font-size: 1.6rem;
  text-align: center;
}

.upload-popup-wrapper .header .close {
  display: block;
}

/* body title */
.upload-popup-wrapper .body .title {
  font-size: 1rem;
  padding: 0 20px;
}
.upload-popup-wrapper .body form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* styles for edit section and edit lesson  popup wrapper  */
.upload-popup-wrapper.delete-file-is-open {
  filter: brightness(30%);
  background-color: #ffffff;
}
.upload-popup-wrapper .body form .section-edit-wrapper {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-bottom: 15px;
}
.upload-popup-wrapper .body form .section-edit-wrapper.upload,
.upload-popup-wrapper .body form .section-edit-wrapper.files-added {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 20px 0 0 0; */
}

.upload-popup-wrapper .body form .section-edit-wrapper.upload .upload-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30vw;
  padding: 5px 20px;
  max-width: 400px;
  background-color: #797979;
  color: #ffffff;
  border-radius: 12px;
}
.upload-popup-wrapper .body form .section-edit-wrapper.files-added p {
  margin: 0;
}
.upload-popup-wrapper .body form .section-edit-wrapper.links {
  margin-top: 20px;
}
.upload-popup-wrapper .body form .section-edit-wrapper .lesson-files,
.upload-popup-wrapper .body form .section-edit-wrapper .lesson-links {
  background-color: #f2f2f2;
  padding: 20px;
}

.upload-popup-wrapper .body form .section-edit-wrapper .lesson-links {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
}
.upload-popup-wrapper
  .body
  form
  .section-edit-wrapper
  .lesson-links
  .file-actions {
  display: flex;
  justify-content: center;
  gap: 50px;
  width: 50%;
}

.upload-popup-wrapper
  .body
  form
  .section-edit-wrapper
  .lesson-files
  .lesson-file-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
  width: 100%;
}

.upload-popup-wrapper
  .body
  form
  .section-edit-wrapper
  .lesson-files
  .lesson-file-wrapper:hover {
  border-bottom: 1px solid #000000;
}

.upload-popup-wrapper
  .body
  form
  .section-edit-wrapper
  .lesson-files
  .lesson-file-wrapper
  .file-name {
  width: 50%;
}
.upload-popup-wrapper
  .body
  form
  .section-edit-wrapper
  .lesson-files
  .lesson-file-wrapper
  .file-actions {
  display: flex;
  justify-content: center;
  gap: 50px;
  width: 50%;
}

.upload-popup-wrapper .body form .verify-delete label {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 30px;
}

/* .upload-popup-wrapper .body form .verify-delete label.delete-section {
  font-size: 15px;
} */
.upload-popup-wrapper .body form .verify-delete label.delete-section span {
  width: 53%;
}
.upload-popup-wrapper .body form .verify-delete label input {
  width: 17px;
  height: 17px;
}

/* styles for add lesson  popup wrapper no margin  */

.upload-popup-wrapper .body form .all-lesson-wrapper {
  display: flex;
  flex-direction: column;
  width: 70%;
  /* max-width: 350px; */
}

.upload-popup-wrapper .body form .enable_links {
  background-color: #cb2a27;
  color: #ffffff;
  border-radius: 12px;
  margin-top: 10px;
  padding: 5px 10px;
  width: 100px;
}

.upload-popup-wrapper .body form .all-lesson-wrapper .links {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.upload-popup-wrapper .body form .all-lesson-wrapper .links .add_link {
  background-color: #cb2a27;
  color: #ffffff;
  border-radius: 12px;
  margin-top: 10px;
  padding: 5px 10px;
  width: 100px;
  /* max-width: 350px; */
}
.upload-popup-wrapper .body form .all-lesson-wrapper.files-uploaded {
  text-align: center;
  margin: 10px 0 0 0;
}
.upload-popup-wrapper .body form .all-lesson-wrapper textarea {
  padding: 20px;
}

.section-edit-wrapper textarea,
.section-edit-wrapper input,
.all-lesson-wrapper textarea,
.all-lesson-wrapper input {
  background: #f2f2f2;
  border-radius: 12px 12px 0px 0px;
  padding-inline-start: 5%;
  margin-top: 10px;
}

.section-edit-wrapper input,
.all-lesson-wrapper input {
  height: 55px;
}
.upload-popup-wrapper .btn-wrapper {
  margin: 15px 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.upload-popup-wrapper .btn-wrapper .delete-lesson-btn,
.upload-popup-wrapper .btn-wrapper .delete-section,
.upload-popup-wrapper .btn-wrapper .edit-section-btn,
.upload-popup-wrapper .btn-wrapper .add-lesson-btn,
.upload-popup-wrapper .btn-wrapper .edit-lesson-btn {
  background-color: #cb2a27;
  border-radius: 55px;
  color: #ffffff;
  width: 40%;
  margin: 0 auto;
  height: 55px;
}
.upload-popup-wrapper .btn-wrapper .delete-lesson-btn,
.upload-popup-wrapper .btn-wrapper .delete-section {
  background-color: #797979;
}
/* styles for add lesson popup */
.upload-popup-wrapper form .upload-lesson {
  display: flex;
  gap: 20px;
  /* margin: 10px 0; */
  background: #797979;
  padding: 10px 25px;
  border-radius: 12px;
  margin-top: 10px;
  width: 50%;
}
.upload-popup-wrapper form .upload-lesson .attach-files-label {
  color: #ffffff;
  cursor: pointer;
}
