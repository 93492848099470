.admin-users,
.super-admin-users {
  /* margin-top: 30px; */
  width: 100%;

  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.admin-users h3 {
  color: #ffffff;
  align-self: flex-start;
}
.admin-users .invite-member,
.admin-users .invite,
.invite-distributor {
  width: 30vw;
  margin-bottom: 0;
  background-color: #000000;
  padding: 15px 40px;
  border-radius: 12px 12px 0 0;
  cursor: pointer;
  display: flex;
  /* justify-content: center;
  gap: 20px;
  align-items: center; */
}
.admin-users .invite-member {
  margin-top: 20px;
}
.admin-users .invite-member div,
.admin-users .invite div,
.admin-users .invite-distributor div {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.admin-users .invite-member button,
.admin-users button,
.admin-users .invite-distributor button {
  background-color: #000000;
  color: #ffffff;
  /* padding: 15px 40px;
  border-radius: 12px;
  cursor: pointer;  */
}

.admin-users .invite-member-form div input[type="submit"] {
  background-color: #cb2a27;
  padding: 10px 30px;
  border-radius: 55px;
  cursor: pointer;
  color: #ffffff;
}
.admin-users .invite-member-form {
  width: 30vw;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* margin: 20px 0; */
  margin-bottom: 20px;
  /* height: 220px; */
  padding: 40px 0;
}
.admin-users .invite-member-form div {
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.admin-users .invite-member-form div input[type="email"],
.admin-users .invite-member-form div select {
  background-color: #535353;
  border-radius: 12px 12px 0 0;
  border-bottom: 1px solid black;
  width: 80%;
  height: 6vh;
  padding-inline-start: 20px;
  font-size: 1rem;
  color: #ffffff;
}
.admin-users .invite-member-form div input[type="email"]::placeholder {
  color: #ffffff;
}
.admin-users .accordion-wrapper {
  width: 100%;
  overflow-x: hidden;
  cursor: pointer;
}

.mobile-accordion-wrapper {
  overflow-y: scroll;
  cursor: pointer;
  width: 80%;
  padding: 20px 0;
}

.mobile-accordion-wrapper::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.admin-users .accordion-wrapper .title,
.mobile-accordion-wrapper .title {
  display: flex;
  justify-content: space-between;
  padding: 0 10%;
  color: #ffffff;
  font-size: 1rem;
}
.admin-accordion .no-users {
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  margin-top: 100px;
}
.admin-users .accordion-wrapper .admin-accordion .accordion {
  width: 100%;
}

.admin-users
  .accordion-wrapper
  .admin-accordion
  .accordion
  .admin-accordion-item,
.mobile-accordion-wrapper .admin-accordion .accordion .admin-accordion-item {
  margin-top: 10px;
  border: none;
  border-radius: 12px 12px 0 0;
}

.admin-users
  .accordion-wrapper
  .admin-accordion
  .accordion
  .admin-accordion-item
  .accordion-collapse {
  overflow-y: scroll;
}

.mobile-accordion-wrapper .admin-accordion .accordion .admin-accordion-item {
  /* background-color: #cb2a27; */
  border-radius: 12px 12px 0 0;
  background-color: transparent;
}
.admin-users
  .admin-accordion-item
  h2.admin-accordion-header
  button
  span:first-child,
.mobile-accordion-wrapper
  .admin-accordion-item
  h2.admin-accordion-header
  button
  span:first-child {
  flex-grow: 1;
}

/* mobile */
.admin-users
  .mobile-accordion-wrapper
  .admin-accordion
  .accordion
  .admin-accordion-item
  h2.admin-accordion-header
  button {
  background-color: rgba(203, 42, 39, 1);
  color: #ffffff;
}
/* desktop */
.admin-users
  .accordion-wrapper
  .admin-accordion
  .accordion
  .admin-accordion-item
  h2.admin-accordion-header
  button {
  background-color: black;
  color: #ffffff;
  border-radius: 12px 12px 0 0;
  box-shadow: none;
  border: none;
}

.admin-users
  .accordion-wrapper
  .admin-accordion
  .accordion
  .admin-accordion-item
  h2.admin-accordion-header
  button
  :not(.collapsed) {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #ffffff;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  padding-inline-start: 10%;
}

.admin-users
  .accordion-wrapper
  .admin-accordion
  .accordion
  .admin-accordion-item
  h2.admin-accordion-header
  button
  :not(.collapsed) {
  padding-inline-start: 0;
}

.admin-users
  .accordion-wrapper
  .admin-accordion
  .accordion
  .admin-accordion-item
  h2.admin-accordion-header
  button
  div {
  padding-left: 60%;
  width: 100%;
  flex-grow: 1;
}

/* DESKTOP for ADMIN view */
/* .admin-users
  .accordion-wrapper
  .admin-accordion
  .accordion
  .admin-accordion-item
  .accordion-collapse.collapse.show
  .admin-accordion-body {
  height: 220px;
} */

.admin-users .accordion-wrapper .admin-accordion .accordion form {
  display: flex;
  justify-content: center;
  gap: 5px;
}
.admin-accordion .accordion .admin-user-details-form {
  display: flex;
  justify-content: space-evenly;
  gap: 5px;
}
.accordion-wrapper
  .admin-accordion
  .accordion
  .admin-accordion-item
  .accordion-collapse.collapse.show
  .admin-accordion-body
  .form-container
  form
  div
  input,
.accordion-collapse.collapse.show
  .admin-accordion-body
  .form-container
  form
  div
  select {
  font-size: 12px;
  text-align: center;
  width: 10vw;
  border-radius: 12px 12px 0 0;
  height: 42px;
  background-color: #f2f2f2;
  color: #797979;
  border-bottom: 1px solid #000000;
}

.admin-accordion-body .accordion-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  /* padding: 0 20px; */
  margin-block-start: 20px;
  gap: 20px;
}

.admin-accordion-body .accordion-bottom .status-wrapper .info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 10px;
  padding: 10px 20px 0 10px;
}

.admin-accordion-body .accordion-bottom .buttons-wrapper {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
}
.admin-accordion-body .accordion-bottom .buttons-wrapper button {
  padding: 8px 10px;
  width: 45%;
  max-width: 180px;
  font-size: 1rem;
  background-color: #cb2a27;
  border-radius: 55px;
  color: #ffffff;
}
.admin-accordion-body .accordion-bottom .buttons-wrapper button:first-child {
  background-color: #858585;
}
.admin-accordion .pending-users-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  padding: 20px 10px;
  cursor: default;
}
.admin-accordion .pending-users-wrapper .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px 20px 10px;
}
.admin-accordion .pending-users-wrapper .header h3 {
  color: #ffffff;
  font-size: 1.1rem;
  font-weight: 600;
}
.admin-accordion .pending-users-wrapper .header h3.invitation {
  padding-inline-end: 40px;
}

.admin-accordion .pending-users-wrapper .pending-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  color: #ffffff;
}

.admin-accordion .pending-users-wrapper .pending-user span.invitation {
  font-size: 0.8rem;
}
.verify-user-deletion {
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  margin: 15px 0;
}
.verify-user-deletion label {
  font-size: 0.8rem;
}
.checkbox-not-checked {
  text-align: center;
  color: #cb2a27;
  font-size: 0.8rem;
}
/* DESKTOP for SUPER ADMIN view  */

/* 
.super-admin-users .super-accordion-body .member {
  display: flex;
  justify-content: space-evenly;
} */

/* MOBILE */
.admin-home-page-mobile {
  height: 100vh;
  overflow-y: scroll;
}
.admin-home-page-mobile::-webkit-scrollbar {
  display: none;
}

.admin-home-page-mobile h3.admin-invite-member {
  margin-top: 50px;
}

.mobile-admin-accordion-body {
  background-color: #ffffff;
  /* height: 460px; */
}
.mobile-admin-accordion-body .form-container form {
  display: flex;
  flex-direction: column;
}
.mobile-admin-accordion-body .form-container form div {
  width: 100%;
  text-align: center;
}
.mobile-admin-accordion-body .form-container form div input,
.mobile-admin-accordion-body .form-container form div select {
  background-color: #f2f2f2;
  height: 50px;
  width: 80%;
  border-radius: 12px 12px 0 0;
  border-bottom: 1px solid black;
  padding-inline-start: 20px;
  margin-bottom: 15px;
}
.mobile-admin-accordion-body .accordion-bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.admin-accordion-body .accordion-bottom .status-wrapper,
.mobile-admin-accordion-body .accordion-bottom .status-wrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.mobile-admin-accordion-body .accordion-bottom .status-wrapper {
  justify-content: center;
  width: 70%;
}
.mobile-admin-accordion-body .accordion-bottom .status-wrapper .info {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 20px;
}

.mobile-admin-accordion-body .accordion-bottom .buttons-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.mobile-admin-accordion-body .accordion-bottom .buttons-wrapper button {
  border-radius: 55px;
  background-color: #cb2a27;
  color: #ffffff;
  padding: 8px 10px;
  font-size: 0.7rem;
  margin-top: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.mobile-admin-accordion-body .accordion-bottom .buttons-wrapper button.delete {
  background-color: #858585;
}
@media (min-width: 1131px) {
  .admin-accordion .pending-users-wrapper .header {
    display: flex;
    justify-content: center;
    gap: 100px;
    align-items: center;
    width: 100%;
  }
  .admin-accordion .pending-users-wrapper .pending-user {
    display: flex;
    gap: 80px;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 1130px) {
  .admin-accordion-body .accordion-bottom .buttons-wrapper {
    justify-content: center;
    gap: 10px;
    font-size: 12px;
  }

  .admin-accordion-body .accordion-bottom .status-wrapper .info {
    font-size: 12px;
  }
}

@media (min-width: 390px) {
  .admin-users .admin-accordion-item h2.admin-accordion-header button span,
  .mobile-accordion-wrapper
    .admin-accordion-item
    h2.admin-accordion-header
    button
    span {
    padding: 0 15px;
  }

  .admin-users .accordion-wrapper .title,
  .mobile-accordion-wrapper .title {
    padding: 0 60px;
  }
  .mobile-admin-accordion-body .accordion-bottom .buttons-wrapper button {
    font-size: 1rem;
  }
}
@media (max-width: 389px) {
  .admin-accordion .pending-users-wrapper {
    padding: 20px 2%;
  }
  .admin-accordion .pending-users-wrapper .header {
    display: flex;
    justify-content: center;
    gap: 20%;
  }
  .admin-accordion .pending-users-wrapper .header h3 {
    font-size: 0.9rem;
  }
  .admin-accordion .pending-users-wrapper .header h3.invitation {
    padding-inline-end: 0;
  }
  .admin-accordion .pending-users-wrapper .pending-user span {
    font-size: 0.6rem;
  }
  .admin-accordion .pending-users-wrapper .pending-user span.invitation {
    font-size: 0.6rem;
  }
}
