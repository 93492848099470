.message-edit-delete-popup-container {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -40%);
  background-color: #ffffff;
  padding: 20px;
  width: 80vw;
  max-width: 700px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  z-index: 100;
  overflow-y: scroll;
  overflow-x: hidden;
}

.message-edit-delete-popup-container.delete-file-is-open {
  filter: brightness(30%);
  background-color: #ffffff;
}

.message-edit-delete-popup-container::-webkit-scrollbar {
  width: 8px;
  /* border-radius: 53% 0% 0% 51% / 57% 57% 41% 43%; */
}
/* .message-edit-delete-popup-container::-webkit-scrollbar-track {
} */

.message-edit-delete-popup-container::-webkit-scrollbar-thumb {
  background: #7c7979;
  border-radius: 10px;
}
.message-edit-delete-popup-container .header {
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
  padding-right: 15px;
}

.message-edit-delete-popup-container .message-delete-buttons {
  display: flex;
  justify-content: space-evenly;
  margin: 30px 0;
  width: 100%;
}
.message-edit-delete-popup-container .message-delete-buttons button {
  border-radius: 55px;
  padding: 2% 10%;
  font-size: clamp(0.875rem, 0.8068rem + 0.3896vw, 1.25rem);
  color: #ffffff;
}
.message-edit-delete-popup-container
  .message-delete-buttons
  .message-delete-cancel {
  background: #cb2a27;
}

.message-edit-delete-popup-container .message-delete-buttons .message-delete,
.message-edit-delete-popup-container .save-message {
  background: #858585;
}
/* edit message form  */

.message-edit-delete-popup-container form.message-edit-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
form.message-edit-form .form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60vw;
}
form.message-edit-form .form-wrapper label,
form.message-edit-form .form-wrapper p {
  text-align: left;
  width: 40%;
  font-size: clamp(0.8rem, 0.8068rem + 0.3896vw, 1.25rem);

  margin: 20px 0 5px 0;
}

form.message-edit-form .form-wrapper p.files-added {
  text-align: center;
  margin: 0px 0 17px 0;
}

form.message-edit-form .form-wrapper input,
form.message-edit-form .form-wrapper textarea {
  background-color: #f2f2f2;
  border-radius: 12px 12px 0 0;
  height: 40px;
  font-size: 1.2rem;
  padding-inline-start: 20px;
  border-radius: 12px 12px 0 0;
  height: 55px;
  width: 40vw;
  max-width: 400px;
  background-color: #f2f2f2;
  border-bottom: 1px solid #000000;
}
form.message-edit-form .form-wrapper textarea {
  min-height: 150px;
}

form.message-edit-form .form-wrapper .files {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40vw;
  margin-bottom: 30px;
}
form.message-edit-form .form-wrapper .files .file {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  background-color: #f2f2f2;
  width: 40vw;
  max-width: 400px;
}
form.message-edit-form .form-wrapper .files .file:hover {
  border-bottom: 1px solid #000000;
}
/* form.message-edit-form .form-wrapper .files .file span:hover {
  color: #cb2a27;
} */

form.message-edit-form .form-wrapper .files .file .message-delete-icon {
  font-size: clamp(0.875rem, 0.8068rem + 0.3896vw, 1.25rem);
}

form.message-edit-form .form-wrapper .files .file .message-delete-icon:hover {
  color: #cb2a27 !important;
}

form.message-edit-form .form-wrapper .files .file .file-name {
  padding: 10px 0;
  width: 20vw;
}
form.message-edit-form .form-wrapper .files .file .file-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 20%;
}
/* form.message-edit-form .form-wrapper .files .file .file-delete {
  width: 20vw;
  display: flex;
  justify-content: center;
} */

form.message-edit-form .form-wrapper .files .file .file-name span {
  font-size: clamp(0.875rem, 0.8068rem + 0.3896vw, 1.25rem);
}
form.message-edit-form .form-wrapper .upload {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30vw;
  padding: 5px 20px;
  max-width: 400px;
  background-color: #797979;
  color: #ffffff;
  border-radius: 12px;
}
form.message-edit-form .form-wrapper .upload label.add-files {
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

form.message-edit-form .form-wrapper .upload label.add-files span {
  font-size: 1.25rem;
}

/* titles are the same for both edit form and delete form */
.message-edit-delete-popup-container .edit-delete-title {
  text-align: center;
  width: 70%;
  margin: 0 auto;
}
.message-edit-delete-popup-container .edit-delete-title h1 {
  font-size: clamp(0.875rem, 0.7599rem + 0.6579vw, 1.5rem);
  font-weight: 400;
}
.message-edit-delete-popup-container .error {
  text-align: center;
  font-size: clamp(0.875rem, 0.7599rem + 0.6579vw, 1.5rem);
  font-weight: 600;
  margin-bottom: 10px;
}
@media (max-width: 500px) {
  form.message-edit-form .form-wrapper .files .file .file-actions {
    width: 40%;
  }
  form.message-edit-form .form-wrapper .files .file {
    padding: 0 10px;
  }

  form.message-edit-form .form-wrapper input,
  form.message-edit-form .form-wrapper textarea {
    font-size: 0.9rem;
  }
  form.message-edit-form .form-wrapper .upload label.add-files span {
    font-size: 0.7rem;
  }
  form.message-edit-form .form-wrapper .upload {
    padding: 5px 10px;
    width: 65%;
  }
  /* .message-edit-delete-popup-container .message-delete-buttons {
    width: 100%;
  } */
  .message-edit-delete-popup-container .message-delete-buttons button {
    padding: 2% 5%;
  }
}
