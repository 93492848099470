/* MOBILE */
.super-admin-users
  .mobile-accordion-wrapper
  .mobile-super-admin-accordion-body {
  background-color: #ffffff;
  cursor: pointer;
  padding: 10px;
}
.super-admin-users
  .mobile-accordion-wrapper
  .mobile-super-admin-accordion-body:last-child,
.super-admin-users .super-accordion-body:last-child {
  padding-bottom: 30px;
}

.member {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(220, 220, 220, 1);
}

.member .member-percent {
  display: flex;
  justify-content: center;
}

/* DESKTOP */
.super-admin-users
  .accordion-wrapper
  .admin-accordion
  .accordion
  .admin-accordion-item
  h2.admin-accordion-header
  button
  div {
  padding-left: 0px;
}

.super-admin-users
  .accordion-wrapper
  .admin-accordion
  .accordion
  .admin-accordion-item
  h2.admin-accordion-header
  button
  :not(.collapsed):not(:first-child) {
  padding-inline-start: 0;
}

.super-admin-users
  .accordion-wrapper
  .admin-accordion
  .accordion
  .admin-accordion-item
  .accordion-collapse.collapse.show
  .super-accordion-body {
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px;
}

.member .member-name p,
.member .member-email,
.member .auth_level,
.member .percent {
  flex: 1;
  text-align: left;
}
.member .member-name p {
  width: 70px;
}
.member .member-email {
  min-width: 100px;
}
.member .auth_level {
  width: 15%;
  width: 100px;
}
.member .percent {
  width: 15%;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 33vh;
}
.super-admin-users .admin-accordion .pending-users {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 100%;
  border-radius: 8px;
}
.super-admin-users .admin-accordion .pending-users .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px 0 50px;
}
.super-admin-users .admin-accordion .pending-users h3 {
  padding: 20px 0 0 40px;
}
.super-admin-users .admin-accordion .pending-users .header div {
  color: #ffffff;
  font: 1rem;
  font-weight: 600;
}
.super-admin-users .admin-accordion .pending-users .pending-user {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Three equal columns */
  align-items: center;
  padding: 10px;
}

.super-admin-users .admin-accordion .pending-users .pending-user div {
  color: #ffffff;
  font-size: 0.8rem;
  text-align: center; /* Center the content horizontally */
  overflow: hidden; /* Prevent content from overflowing */
  white-space: nowrap; /* Prevent content from wrapping */
  text-overflow: ellipsis; /* Add ellipsis (...) if content overflows */
  padding: 5px; /* Add padding for better spacing */
}
@media (min-width: 950px) {
  .super-admin-users .accordion-wrapper .super-accordion-body .member {
    font-size: 1rem;
    padding: 0 20px;
  }
  .super-admin-users .admin-accordion .pending-users .header {
    justify-content: space-around;
    padding: 10px 20px 0 20px;

    gap: 8%;
  }
}

@media (max-width: 450px) {
  .super-admin-users .admin-accordion .pending-users .header {
    padding: 20px 20px 0 20px;
  }
  .super-admin-users .admin-accordion .pending-users .header div {
    font-size: 0.8rem;
  }
}

@media (max-width: 350px) {
  .super-admin-users .admin-accordion .pending-users .header {
    padding: 20px 10px 0 10px;
  }
}
