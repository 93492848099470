.user-community-title {
  color: #ffffff;
  padding: 30px;
}
.user-top-messages {
  height: 290px;
  overflow-y: scroll;
  width: 100%;
  cursor: pointer;
}
.user-top-messages::-webkit-scrollbar {
  display: none;
}
.user-community-wrapper .user-new-messages-wrapper,
.user-community-wrapper .user-archive-messages-wrapper {
  background-color: #efefef;
  border-radius: 12px;
  /* width: 50vw; */
  width: 100%;
  /* height: 150px; */
  padding: 15px;
  height: fit-content;
  margin-bottom: 20px;
}

.user-community-wrapper .user-new-messages-wrapper.no-height {
  height: auto;
}

.user-community-wrapper .user-new-messages-wrapper .date-location,
.user-community-wrapper .user-archive-messages-wrapper .date-location {
  display: flex;
  justify-content: space-between;
}
.user-community-wrapper .user-new-messages-wrapper .main,
.user-community-wrapper .user-archive-messages-wrapper .main {
  display: flex;
  flex-direction: column;
}

.user-community-wrapper .user-new-messages-wrapper .main .article-btn,
.user-community-wrapper .user-archive-messages-wrapper .main .article-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-community-wrapper .user-new-messages-wrapper .main .article-btn button,
.user-community-wrapper
  .user-archive-messages-wrapper
  .main
  .article-btn
  button {
  background-color: #620402;
  padding: 5px 12px;
  border-radius: 55px;
  color: #ffffff;
  font-size: 1rem;
  margin-top: 10px;
  margin-bottom: 10px;
}
.user-community-wrapper .separator {
  color: #ffffff;
  display: flex;
  align-items: baseline;
  margin: 20px 0;
  font-size: 1.5rem;
}
.user-community-wrapper .separator:after,
.user-community-wrapper .separator:before {
  position: relative;
  top: 3px;
  border-bottom: 1px dashed #ffffff;
  content: "";
  flex: 1;
}

.user-archive-messages {
  overflow-y: scroll;
  width: 100%;
  height: 60vh;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
}
.user-archive-messages::-webkit-scrollbar {
  display: none;
}
